import { useRef, useState } from "react";
import { AccordionButtons, AccordionItem, AccordionItemInterface } from "../../Accordion";
import ShippingMethodSelector, { SelectorRef } from "./ShippingMethodSelector";
import { useToast } from "../../../hooks";
import { IneligibleShippingMethodError, NoActiveOrderError, OrderModificationError } from "../../../gql/graphql";
import { useTranslation } from "react-i18next";
import { AccordionButtonInterface } from "../../Accordion/AccordionButtons";

export default function ShippingRow({ onPrevious, onNext, onOpened, onClosed, ...rest }: AccordionButtonInterface & AccordionItemInterface) {

    const { t } = useTranslation("checkout");

    const [ready, setReady] = useState(false);

    const toast = useToast();

    const selector = useRef<SelectorRef>(null);

    return (
        <AccordionItem title={t("select_shipping_method")} onOpened={onOpened} onClosed={onClosed} {...rest}>
            <ShippingMethodSelector {...{ref: selector}} onReady={(ready) => {
                setReady(ready);
            }} />

            <AccordionButtons {...{onPrevious}} isReady={ready} onNext={() => {
                selector.current?.submit().then((result) => {
                    switch(result.__typename) {
                        case "Order":
                            onNext?.();
                            break;
                        case "IneligibleShippingMethodError":
                            toast.open((result as IneligibleShippingMethodError).message);
                            break;
                        case "NoActiveOrderError":
                            toast.open((result as NoActiveOrderError).message);
                            break;
                        case "OrderModificationError":
                            toast.open((result as OrderModificationError).message);
                            break;
                    }
                }).catch((reason) => {
                    toast.open(reason);
                });
            }} />
        </AccordionItem>
    );
}
