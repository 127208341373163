import { createContext } from 'react';

interface CookieConsentInterface {
    consent: Boolean;
    approve: () => void;
    decline: () => void;
}

const defaultState = {
    consent: false
};

export const CookieConsentContext = createContext<Partial<CookieConsentInterface>>(defaultState);
