import { useEffect, useRef, useState } from "react";
import { SearchAction, SearchRef } from "../../actions/SearchAction";
import { ProductsGrid } from "../../components/ProductSearch";
import { useTranslation } from "react-i18next";
import { SearchResult } from "../../gql/graphql";
import { Namespace, TFunction } from "i18next";

const getContent = (search: Array<SearchResult>, t: TFunction<Namespace, undefined>) => {
    if (search.length > 0) {
        return (<ProductsGrid items={search} />);
    } else {
        return (<h3>{t("no_products_available")}</h3>);
    }
}

export default function MadeToOrder() {
    const searchAction = useRef<SearchRef>(null);

    const { t } = useTranslation("madeToOrder");

    const [search, setSearch] = useState<Array<SearchResult>>([]);

    useEffect(() => {
        searchAction.current?.madeToOrder();
    }, [searchAction]);

    return (
        <>
            <div className="row">
                <div className="column">
                    <h1>{t("title")}</h1>
                    <p>{t("description")}</p>
                    <p>{t("return_policy")}</p>
                    <SearchAction ref={searchAction} onSearch={(items) => {
                        setSearch(items);
                    }} />

                    {getContent(search, t)}
                </div>
            </div>
        </>
    );
}
