import { Navigate } from "react-router-dom";
import { RegisterForm } from "../../components/RegisterForm";
import { useState } from "react";

export default function RegisterPage() {

    const [result, setResult] = useState(false);
 
    if (result) {
        return <Navigate to="/registered" replace />
    }

    return (
        <>
            <div className="grid-container">
                <div className="row">
                    <div className="sixteen column">
                        <RegisterForm onResult={(success) => {
                            setResult(success);
                        }}/>
                    </div>
                </div>
            </div>
        </>
    );
}
