import { ApolloQueryResult, FetchResult, useMutation } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { CreateStripePaymentIntentQuery } from ".";

type Result = {
    createStripePaymentIntent: string;
}

type Props = {
    onCompleted?: (clientSecret: string) => void;
}

export interface StripePaymentRef {
    create: () => Promise<FetchResult<Result>>,
    resetStore: () => Promise<ApolloQueryResult<Result>[] | null>;
}

function StripePaymentAction (
    { onCompleted }: Props,
    ref: Ref<StripePaymentRef>
) {
    const [mutate, { client }] = useMutation<Result>(CreateStripePaymentIntentQuery, {
        onCompleted: (data) => {
            onCompleted?.(data.createStripePaymentIntent);
        }
    });

    const create = (): Promise<FetchResult<Result>> => mutate()

    const resetStore = () => (
        client.resetStore()
    );

    useImperativeHandle(ref, () => ({ create, resetStore }));

    return <></>;
}

export default React.forwardRef(StripePaymentAction);
