import { createContext } from 'react';
import { Order } from '../gql/graphql';

interface ActiveOrderContextInterface {
    order?: Order;
}

const defaultState = {
    order: undefined
};

export const ActiveOrderContext = createContext<Partial<ActiveOrderContextInterface>>(defaultState);
