import { gql } from "@apollo/client";

export const EligibleShippingMethodsQuery = gql`
    query EligibleShippingMethods {
        eligibleShippingMethods {
            id
            price
            priceWithTax
            name
            description
        }
    }
`;
