import { gql } from "@apollo/client";

export const PublicDataQuery = gql`
query PublicData {
    getPublicData {
        streetAddress
        postal
        zip

        openHours
        phone
        email

        instagramUri
        facebookUri
        pinterestUri
        etsyUri

        affiliates
        affiliates_titles

        toastTimeout
    }
}
`;
