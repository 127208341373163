import React, { Ref, createRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { renderForm } from "@agoransson/formhelper";
import { useTranslation } from "react-i18next";
import { OrderAction, OrderRef } from "../../../actions/OrderAction";
import { SetCustomerForOrderResult } from "../../../gql/graphql";
import { validateEmail } from "../../helpers";

type Props = {
    orderId?: string;
    onFormReady?: (ready: boolean) => void;
}

export interface FormRef {
    submit: () => Promise<SetCustomerForOrderResult>;
}

function GuestCustomerForm(
    { onFormReady }: Props,
    ref: Ref<FormRef>
) {
    const orderAction = useRef<OrderRef>(null);

    let formRef = createRef<HTMLFormElement>();

    const { t } = useTranslation("checkout");

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [emailAddress, setEmailAddress] = useState<string>("");

    const handleEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(evt.currentTarget.value);
    };

    const handleFirstName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(evt.currentTarget.value);
    };

    const handleLastName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(evt.currentTarget.value);
    };

    const customerForm = [
        [
            {type: 'text', name: 'firstName', placeholder: t("first_name"), onChange: handleFirstName, value: firstName},
            {type: 'text', name: 'lastName', placeholder: t("last_name"), onChange: handleLastName, value: lastName}
        ],
        [
            {type: 'email', name: 'email', placeholder: t("email"), onChange: handleEmail, value: emailAddress},
        ],
    ];

    const submit = (): Promise<SetCustomerForOrderResult> => {
        return new Promise<SetCustomerForOrderResult>((resolve, reject) => {
            if (!firstName || !lastName || !emailAddress || !validateEmail(emailAddress)) {
                reject(t("missing_required_data"));
            } else  if (orderAction.current) {
                orderAction.current?.setCustomer({
                    firstName,
                    lastName,
                    emailAddress
                }).then((result) => {
                    resolve(result);
                }).catch((reason) => {
                    reject(reason);
                });
            } else {
                reject(t("client_error"));
            }
        });
    };

    useImperativeHandle(ref, () => ({ submit }));

    useEffect(() => {
        const isNotReady = !firstName || !lastName || !emailAddress;

        if (isNotReady) {
            return;
        }

        onFormReady?.(!isNotReady);
    }, [firstName, lastName, emailAddress, onFormReady]);

    return (
        <>
            <OrderAction ref={orderAction} />
            <form style={{ marginBottom: '0'}} ref={formRef}>
                <fieldset className="fieldset" style={{ paddingBottom: '0', marginBottom: '0', paddingTop: '0'}}>
                    {renderForm(customerForm)}
                </fieldset>
            </form>
        </>
    );
}

export default React.forwardRef(GuestCustomerForm);
