import { gql } from "@apollo/client";

export const UpdateCustomerQuery = gql`
mutation UpdateCustomerQuery($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
        id
        firstName
        lastName
        phoneNumber
        emailAddress
        user {
            id
            identifier
        }
    }
}
`;
