import { gql } from "@apollo/client";

export const ProductFragment = gql`
    fragment ProductFragment on Product {
        __typename
        id
        name
        slug
        description
    }
`

export const AssetFragment = gql`
    fragment AssetFragment on Product {
        assets {
            id
            name
            source
            preview
            width
            height
        }
    }
`

export const VariantsFragment = gql`
    fragment VariantsFragment on Product {
        variants {
            id
            options {
                code
                name
                group {
                    code
                    name
                }
            }
            priceWithTax
        }
    }
`

export const OptionGroupsFragment = gql`
    fragment OptionGroupsFragment on Product {
        optionGroups {
            id
            code
            name
            options {
                id
                code
                name
            }
        }
    }
`
