import { gql } from "@apollo/client";

export const OrderByCodeQuery = gql`
query OrderByCode($code: String!) {
    orderByCode(code: $code) {
        __typename
        id
        active
        code
        shipping
        shippingWithTax
        subTotal
        subTotalWithTax
        total
        totalWithTax
        orderPlacedAt
        shippingAddress {
            fullName
            streetLine1
            streetLine2
            city
            postalCode
            country
            countryCode
            phoneNumber
            customFields
        }
        billingAddress {
            fullName
            streetLine1
            streetLine2
            city
            postalCode
            country
            countryCode
            phoneNumber
            customFields
        }
        shippingLines {
            shippingMethod {
                id
                name
                description
            }
            price
        }
        payments {
            id
            method
            state
            transactionId
            errorMessage
            metadata
        }
        lines {
            id
            featuredAsset {
                preview
            }
            linePriceWithTax
            productVariant {
                name
                price
                product {
                    name
                }
                priceWithTax
                options {
                    name
                    code
                    group {
                        name
                        code
                    }
                }
            }
            unitPrice
            unitPriceWithTax
            quantity
        }
    }
}
`;
