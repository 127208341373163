import React, { Ref, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ShippingMethodAction, ShippingMethodRef } from "../../../actions/ShippingMethodAction";
import { SetOrderShippingMethodResult, ShippingMethodQuote } from "../../../gql/graphql";
import SelectorItem from "./SelectorItem";
import classes from "./ShippingMethodSelector.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
    onReady?: (ready: boolean) => void;
};

export interface SelectorRef {
    submit: () => Promise<SetOrderShippingMethodResult>;
}

function ShippingMethodSelector(
    { onReady }: Props,
    ref: Ref<SelectorRef>
) {
    const { t } = useTranslation("checkout");

    const shippingMethodAction = useRef<ShippingMethodRef>(null);

    const [loaded, setLoaded] = useState(false);
    const [shippingMethods, setShippingMethods] = useState<ShippingMethodQuote[] | undefined>();
    const [selectedShippingId, setSelectedShippingId] = useState<string>();

    useEffect(() => {
        if (!loaded) {
            shippingMethodAction.current?.get().then((result) => {
                setShippingMethods(result);
            })
            setLoaded(true);
        }
    }, [loaded, setLoaded]);

    useEffect(() => {
        onReady?.(selectedShippingId !== undefined);
    }, [selectedShippingId, onReady]);

    const submit = (): Promise<SetOrderShippingMethodResult> => {
        return new Promise<SetOrderShippingMethodResult>((resolve, reject) => {
            if (selectedShippingId === undefined) {
                reject(t("undefined_shipping_method"));
            } else if (shippingMethodAction.current) {
                shippingMethodAction.current.set([selectedShippingId]).then((result) => {
                    resolve(result);
                }).catch((reason) => {
                    reject(reason);
                });
            } else {
                reject(t("client_error"));
            }
        });
    };

    useImperativeHandle(ref, () => ({ submit }));

    return (
        <>
            <ShippingMethodAction ref={shippingMethodAction} />

            <div className={classes.container}>
                {shippingMethods?.map((shippingMethod) => (
                    <SelectorItem key={shippingMethod.id} {...{shippingMethod, selected: selectedShippingId === shippingMethod.id}} onClick={() => {
                        setSelectedShippingId(shippingMethod.id);
                    }} />
                ))}
            </div>
        </>
    );
}

export default React.forwardRef(ShippingMethodSelector);
