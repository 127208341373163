import { gql } from "@apollo/client";

export const AdjustOrderLineQuery = gql`
    mutation AdjustOrderLine($orderLineId: ID!, $quantity: Int!) {
        adjustOrderLine(orderLineId: $orderLineId, quantity: $quantity) {
            ... on Order {
                __typename
                id
                code
                shipping
                shippingWithTax
                subTotal
                subTotalWithTax
                total
                totalWithTax
                shippingAddress {
                    fullName
                    streetLine1
                    streetLine2
                    city
                    postalCode
                    country
                    countryCode
                    phoneNumber
                    customFields
                }
                shippingLines {
                    shippingMethod {
                        id
                        name
                        description
                    }
                    price
                }
                payments {
                    id
                    method
                    state
                    transactionId
                    errorMessage
                    metadata
                }
                lines {
                    id
                    featuredAsset {
                        source
                    }
                    linePriceWithTax
                    productVariant {
                        name
                        price
                        product {
                            name
                        }
                        priceWithTax
                        options {
                            name
                            group {
                                name
                            }
                        }
                    }
                    quantity
                    unitPrice
                    unitPriceWithTax
                }
            }
            ... on OrderModificationError {
                __typename
                message
            }
            ... on OrderLimitError {
                __typename
                message
            }
            ... on NegativeQuantityError {
                __typename
                message
            }
            ... on InsufficientStockError {
                __typename
                message
            }
        }
    }
`;
