import classes from './productitem.module.scss';
import { Link } from 'react-router-dom';
import { priceWithDecimals } from '../helpers';
import { useTranslation } from 'react-i18next';
import { Namespace, TFunction } from 'i18next';
import { SearchResult, SearchResultPrice } from '../../gql/graphql';

const renderPrice = (priceWithTax: SearchResultPrice, t: TFunction<Namespace, undefined>) => {
    switch (priceWithTax.__typename) {
        case "PriceRange": {
            return `${t("price_from")}${priceWithDecimals(priceWithTax.min, t("unit"))}`;
        }
        case "SinglePrice": {
            return `${t("price_from")}${priceWithDecimals(priceWithTax.value, t("unit"))}`;
        }
    }
}

export default function ProductItem({ slug, productName, productAsset, priceWithTax }: SearchResult) {
    const { t } = useTranslation("priceTag");

    return (
        <>
            <Link to={`/product/${slug}`} className={classes.productlink}>
                <div className={classes.listitem}>
                    <img className={classes.listitem_image} alt={productName} src={`${productAsset?.preview}?preset=grid`} />
                    <p className={classes.productName}>{productName}</p>
                    <p>{renderPrice(priceWithTax, t)}</p>
                </div>
            </Link>
        </>
    );
}
