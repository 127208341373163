import React, { JSXElementConstructor, ReactElement, Ref, useImperativeHandle } from "react";
import { useEffect, useState } from 'react';
import { usePrevious } from '../usePrevious';
import { AccordionItemProps } from './AccordionItem';

export interface AccordionRef {
    next: () => void;
    previous: () => void;
}

type AccordionElement = ReactElement<AccordionItemProps, JSXElementConstructor<AccordionItemProps>>

type AccordionProps = {
    children: Array<AccordionElement | undefined>
}

const filterChildren = (children: Array<AccordionElement | undefined>) => children.filter((child): child is AccordionElement => (!!child));

function Accordion(
    { children }: AccordionProps,
    ref: Ref<AccordionRef>
) {
    // Need to filter "undefined" values from children
    const filtered = filterChildren(children);

    const [open, setOpen] = useState(0);
    const [blockSignal, setBlockSignal] = useState(true);

    const previousOpen = usePrevious(open)

    useEffect(() => {
        if (!blockSignal && open !== previousOpen) {
            setBlockSignal(true);

            const filtered = filterChildren(children);
        
            const previous = filtered?.[previousOpen ? previousOpen : 0];
            previous?.props.onClosed?.();

            const next = filtered?.[open];
            next?.props.onOpened?.();
        }
    }, [open, previousOpen, children, blockSignal, setBlockSignal]);

    const next = () => {
        const next = open + 1;
        const newOpen = next >= children?.length ? 0 : next;
        setBlockSignal(false);
        setOpen(newOpen);
    }

    const previous = () => {
        const prev = open - 1;
        const newOpen = prev < 0 ? children?.length - 1 : prev;
        setBlockSignal(false);
        setOpen(newOpen);
    }

    useImperativeHandle(ref, () => ({ next, previous }));

    return (
        <>
            {React.Children.map(filtered, (child, index) => {
                const props = { open: (index === open) };
                return React.cloneElement(child, props, null);  
            })}
        </>
    );
}

export default React.forwardRef(Accordion);
