import React, { ReactNode, Ref, useImperativeHandle } from 'react';
import classes from './Accordion.module.scss';

export interface AccordionItemRef {
    isOpen: () => boolean | undefined;
}

type AccordionItemState = {
    title: string;
    open?: boolean;
    children?: ReactNode;
}

export type AccordionItemInterface = {
    onOpened?: () => void;
    onClosed?: () => void;
}

export type AccordionItemProps = AccordionItemState & AccordionItemInterface;

function AccordionItem(
    { title, open, children }: AccordionItemProps,
    ref: Ref<AccordionItemRef>
) {
    const accordionClasses = open ? [classes.accordion, classes.active] : [classes.accordion];
    const panelClasses = open ? [classes.panel, classes.panelBlock] : [classes.panel];

    const isOpen = (): boolean | undefined => {
        return open;
    };

    useImperativeHandle(ref, () => ({ isOpen }));

    return (
        <>
            <div className={accordionClasses.join(" ")}>{title}</div>
            <div className={panelClasses.join(" ")}>
                {children}
            </div>
        </>
    );
}

export default React.forwardRef(AccordionItem);
