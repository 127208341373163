import { useTranslation } from "react-i18next";
import { priceWithDecimals } from "../../helpers";
import classes from "./ShippingMethodSelector.module.scss";
import { ShippingMethodQuote } from "../../../gql/graphql";

type Props = {
    shippingMethod: ShippingMethodQuote;
    selected: Boolean;
    onClick: () => void;
}

export default function SelectorItem({ shippingMethod, selected, onClick }: Props) {

    const { t } = useTranslation("checkout");

    let containerClasses = [classes.itemContainer];

    if (selected) {
        containerClasses = [classes.selected, ...containerClasses];
    }
    
    return (
        <div className={containerClasses.join(" ")} onClick={() => {
            onClick();
        }}>
            <p className={classes.title}>{shippingMethod.name}</p>
            <p className={classes.price}>{priceWithDecimals(shippingMethod.priceWithTax, t("unit"))} ({t("with_vat")})</p>
            <span className={classes.body} dangerouslySetInnerHTML={{__html: shippingMethod.description}} />
        </div>
    );
}
