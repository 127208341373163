import React from "react";
import { useQuery } from "@apollo/client";
import { Outlet } from "react-router-dom";
import { ActiveCustomerQuery } from "../../actions/ActiveCustomerAction";
import { Header } from "../Header";
import { ConsentComponent } from "../ConsentComponent";
import { Footer } from "../Footer";
import { Customer } from "../../gql/graphql";

type Props = {
    children?: JSX.Element;
};

// const LOADING_WEBSITE = "Webbsidan laddas, vänta.";
// const ERROR_LOADING_CUSTOMER = "Kunde inte ladda kund-data, försök igen.";
// const NO_CUSTOMER_FOUND = "Kunden fanns inte.";

export default function Layout({ children }: Props) {

    const { data, loading, error } = useQuery<Customer>(ActiveCustomerQuery, {});

    if (loading) {
        // TODO: This needs to be put back!
        // return (<LoadingText text={LOADING_WEBSITE} />);
    }

    if (error) {
        console.log(error);
        // TODO: This needs to be put back!
        // return (<LoadingText text={ERROR_LOADING_CUSTOMER} />);
    }

    if (!data) {
        // TODO: This needs to be put back!
        // return (<LoadingText text={NO_CUSTOMER_FOUND} />)
    }

    // const childrenWithProps = React.Children.map(children, child => {
    //     if (React.isValidElement(child)) {
    //         return React.cloneElement(child, {...data});
    //     }
    //     return child;
    // });

    return (
        <>
            <Header />
            {/* <Banner /> */}
            <main>
                <Outlet />
                
                {/* <Script
                    strategy="afterInteractive"
                    src="https://x.klarnacdn.net/kp/lib/v1/api.js" /> */}

                {/* An <Outlet> renders whatever child route is currently active,
                    so you can think about this <Outlet> as a placeholder for
                    the child routes we defined above. */}
                {/* <Outlet /> */}
            </main>
            <ConsentComponent />
            <Footer />
        </>
    )
}
