import CookieConsent from "react-cookie-consent";
import { cookieConsentHeading, cookieConsentName, cookieConsentText, cookieConsentTextNok, cookieConsentTextOk } from "./CookieConsentText";
import classes from './ConsentComponent.module.scss';
import { useRef } from "react";
import { AccountAction, AccountRef } from "../../actions/AccountAction";
import { useConsent } from "../../hooks/UseConsent";

const ConsentContent = () => (
    <>
        <h3 className={classes.heading}>{cookieConsentHeading}</h3>
        <p className={classes.text}>{cookieConsentText}</p>
    </>
);

export default function ConsentComponent() {

    const accountAction = useRef<AccountRef>(null);
    
    const consent = useConsent();

    return (
        <>
            <AccountAction ref={accountAction} />
            <CookieConsent
                buttonText={cookieConsentTextOk}
                enableDeclineButton
                declineButtonText={cookieConsentTextNok}
                cookieName={cookieConsentName}
                sameSite="none"
                cookieSecurity={true}
                overlay={true}
                containerClasses={classes.containerClasses}
                buttonWrapperClasses={classes.buttonWrapperClasses}
                declineButtonClasses={classes.declineButtonClasses}
                buttonClasses={classes.buttonClasses}
                onDecline={() => {
                    consent.decline?.();
                    accountAction.current?.logout().then(() => {
                        accountAction.current?.resetStore();
                    })
                }}
                onAccept={() => {
                    consent.approve?.();
                }}>{ConsentContent()}</CookieConsent>
        </>
    );
}