import { CSSProperties } from "react";
import classes from "./Button.module.scss";
import Loader from "./Loader";

type Props = {
    children: JSX.Element | JSX.Element[] | string | number;
    onClick?: () => void;
    fullWidth?: boolean;
    disabled?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
    style?: CSSProperties | undefined;
    loading?: boolean;
}

export default function Button({ children, onClick, fullWidth, disabled, type, style, loading }: Props) {
    var allClasses = [classes.button];
    
    if (fullWidth) {
        allClasses = [classes.fullWidth, ...allClasses];
    }

    return (
        <>
            <button style={style}
                className={allClasses.join(" ")}
                disabled={disabled || loading}
                type={type}
                onClick={(evt) => {
                    if (type !== "submit") {
                        evt.preventDefault();
                    }
                    if (onClick) {
                        onClick();
                    }
                }}>
                    {!loading ? children : <Loader />}
                </button>
        </>
    );
}
