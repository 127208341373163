export const cookieConsentHeading = "Veras Ateljé hanterar din data ansvarsfullt.";

export const cookieConsentText = `Jag använder cookies för att anpassa och förbättra 
det innehåll som presenteras för dig, allt för att du ska få en så bra upplevelse
som möjligt när du handlar hos mig. Om du tillåter att jag sparar cookies så kan
jag fortsätta leverera en enkel och bra upplevelse för dig.

Om du inte tillåter att jag sparar cookies så fungerar inte webbplatsen fullt ut,
och därmed kommer du inte kunna handla hos mig. Men du är välkommen att kika runt
ändå.
`;

export const cookieConsentTextOk = "Jag förstår, och vill dela min information";
export const cookieConsentTextNok = "Jag önskar inte dela min information";

export const cookieConsentName = "veras_atelje_consent";
