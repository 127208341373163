import { gql } from "@apollo/client";

export const RequestPasswordResetQuery = gql`
    mutation RequestPasswordReset($emailAddress: String!) {
        requestPasswordReset(emailAddress: $emailAddress) {
            ... on Success {
                __typename
                success
            }
            ... on NativeAuthStrategyError {
                __typename
                message
            }
        }
    }
`;
