import { useMutation } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { UpdateCustomerQuery } from "./UpdateCustomerQuery";
import { Customer } from "../../gql/graphql";

type UpdateCustomerInput = {
    title: string
    firstName: string
    lastName: string
    phoneNumber: string
}

type Props = {}

export interface UpdateCustomerRef {
    update: (input: UpdateCustomerInput) => Promise<Customer>,
    resetStore: () => void
}

function UpdateCustomerAction (
    props: Props,
    ref: Ref<UpdateCustomerRef>
) {
    const [mutate, { client }] = useMutation<{updateCustomer: Customer}, {input: UpdateCustomerInput}>(UpdateCustomerQuery);

    const update = ({
        title, firstName, lastName, phoneNumber
    }: UpdateCustomerInput): Promise<Customer> => (
        new Promise<Customer>((resolve, reject) => {
            mutate({
                variables: {
                    input: {
                        title, firstName, lastName, phoneNumber
                    }
                },
                onCompleted: ({ updateCustomer }) => {
                    resolve(updateCustomer);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    );

    const resetStore = () => {
        client.resetStore();
    }

    useImperativeHandle(ref, () => ({ update, resetStore }));

    return <></>;
}

export default React.forwardRef(UpdateCustomerAction);
