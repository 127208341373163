import React, { useState } from 'react';
import { AppContext } from "./contexts";
import AppRoutes from './pages';
import './styles/verasatelje.scss';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import 'keen-slider/keen-slider.min.css'
import { User } from './gql/graphql';

function App() {
    const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);

    const changeCurrentUser = (user: User) => {
        setCurrentUser(user);
    };
    
    return (
        <AppContext.Provider value={{currentUser, changeCurrentUser}}>
            <AppRoutes />

            <ScrollToTop />
        </AppContext.Provider>
    );
}

export default App;
