import { ApolloQueryResult, FetchResult, useLazyQuery } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { ActiveCustomerQuery } from ".";
import { Customer } from "../../gql/graphql";

type Props = {
    onCompleted: (customer: Customer) => void;
}

export interface ActiveCustomerRef {
    get: () => Promise<FetchResult<{activeCustomer: Customer}>>,
    resetStore: () => Promise<ApolloQueryResult<any>[] | null>
}

function ActiveCustomerAction (
    { onCompleted }: Props,
    ref: Ref<ActiveCustomerRef>
) {
    const [query, { client }] = useLazyQuery<{activeCustomer: Customer}>(ActiveCustomerQuery, {
        fetchPolicy: "network-only",
        onCompleted: ({ activeCustomer }) => {
            onCompleted(activeCustomer);
        }
    });

    const get = (): Promise<FetchResult<{activeCustomer: Customer}>> => query()

    const resetStore = () => (
        client.resetStore()
    );

    useImperativeHandle(ref, () => ({ get, resetStore }));

    return <></>;
}

export default React.forwardRef(ActiveCustomerAction);
