import { gql } from "@apollo/client";

export const LoginQuery = gql`
    mutation Login($username: String!, $password: String!, $rememberMe: Boolean) {
        login(username: $username, password: $password, rememberMe: $rememberMe) {
            ... on CurrentUser {
                __typename
                id
                identifier
                channels {
                    id
                    token
                    code
                }
            }
            ... on InvalidCredentialsError {
                __typename
                message
            }
            ... on NotVerifiedError {
                __typename
                message
            }
            ... on NativeAuthStrategyError {
                __typename
                message
            }
        }
    }
`;
