import { ProductOptionProps } from "../ProductOptionSelector";
import classes from "./Size.module.scss";

export default function Size({ code, name, active, onSelect }: ProductOptionProps) {
    const classNames = (active ? [classes.active, classes.container] : [classes.container]).join(" ");

    const handleClick = () => {
        if (code) {
            onSelect?.(code);
        }
    }

    return (
        <div className={classNames} onClick={handleClick}>
            <span>{name?.toUpperCase()}</span>
        </div>
    )
}
