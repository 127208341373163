import { createContext } from 'react';

interface DrawerContextInterface {
    navOpen: boolean;
    openNav?: () => void;
    closeNav?: () => void;

    cartOpen: boolean;
    openCart?: () => void;
    closeCart?: () => void;
}

const defaultState = {
    navOpen: false,
    cartOpen: false,
};

export const DrawerContext = createContext<Partial<DrawerContextInterface>>(defaultState);
