import { useState, useEffect, useRef } from "react";
import { ProductAction, ProductRef } from "../../../actions/ProductAction";
import { FabricSelector } from "../../../components/ProductOptionSelectors/FabricSelector";
import { PriceTag } from "../../../components/PriceTag";
import { SizeSelector } from "../../../components/ProductOptionSelectors/SizeSelector";
import { useParams } from "react-router-dom";
import { FABRIC_OPTION_CODE, LENGTH_OPTION_CODE, SIZE_OPTION_CODE } from "../../../components/helpers";
import { LengthSelector } from "../../../components/ProductOptionSelectors/LengthSelector";
import { useTranslation } from "react-i18next";
import { useDrawers, useLoading, useToast } from "../../../hooks";
import classes from "./productslug.module.scss";
import PreviewSlider from "./PreviewSlider";
import { SocialSharingComponent } from "../../../components/SocialSharingComponent";
import { Asset, Product, ProductOption, ProductOptionGroup, ProductVariant } from "../../../gql/graphql";
import { Button } from "../../../components/Button";
import { AddItemToOrderAction, AddItemToOrderRef } from "../../../actions/AddItemToOrderAction";
import { ProductOptionSelectorRef } from "../../../components/ProductOptionSelectors/ProductOptionSelector";
import { useConsent } from "../../../hooks/UseConsent";

export default function ProductPage() {

    const { consent, approve } = useConsent();

    const { t } = useTranslation("product");

    const { openCart } = useDrawers();

    const toast = useToast();

    const addItemToOrder = useRef<AddItemToOrderRef>(null);

    const sizeSelector = useRef<ProductOptionSelectorRef>(null);
    const lengthSelector = useRef<ProductOptionSelectorRef>(null);
    const fabricSelector = useRef<ProductOptionSelectorRef>(null);

    const { isLoading } = useLoading();

    const productAction = useRef<ProductRef>(null);

    const [product, setProduct] = useState<Product | undefined>();

    const [size, setSize] = useState<ProductOption | undefined>(undefined);
    const [length, setLength] = useState<ProductOption | undefined>(undefined);
    const [fabric, setFabric] = useState<ProductOption | undefined>(undefined);
    const [variant, setVariant] = useState<ProductVariant | undefined>(undefined);
    
    const [assets, setAssets] = useState<Array<Asset>>([]);

    const { slug } = useParams();

    useEffect(() => {
        if (slug) {
            productAction.current?.get(slug);
        }
    }, [slug]);

    useEffect(() => {
        if (product?.assets) {
            setAssets(product.assets);
        }
    }, [product])

    const handleLoadProduct = (products: Array<Product>) => {
        setProduct(products[0]);
    }
    
    const handleSizeSelected = (option: ProductOption | undefined) => {
        setSize(option);
    }
    
    const handleLengthSelected = (option: ProductOption | undefined) => {
        setLength(option);
    }

    const handleFabricSelected = (option: ProductOption | undefined) => {
        setFabric(option);
    }

    useEffect(() => {
        if (product === undefined) {
            setVariant(undefined);
            return;
        }

        const variant = product.variants.find((variant: ProductVariant) => {
            const sizeOption = variant.options.find((option: ProductOption) => (option.group.code === SIZE_OPTION_CODE));
            const lengthOption = variant.options.find((option: ProductOption) => (option.group.code === LENGTH_OPTION_CODE));
            const fabricOption = variant.options.find((option: ProductOption) => (option.group.code === FABRIC_OPTION_CODE));

            return sizeOption?.code === size?.code && fabricOption?.code === fabric?.code && lengthOption?.code === length?.code;
        });

        if (variant) {
            setVariant(variant);
        } else {
            setVariant(undefined);
        }
    }, [size, length, fabric, product]);

    const sizeGroup : ProductOptionGroup | undefined = product?.optionGroups.find((group: ProductOptionGroup) => (group.code === SIZE_OPTION_CODE));
    const lengthGroup : ProductOptionGroup | undefined = product?.optionGroups.find((group: ProductOptionGroup) => (group.code === LENGTH_OPTION_CODE));
    const fabricGroup : ProductOptionGroup | undefined = product?.optionGroups.find((group: ProductOptionGroup) => (group.code === FABRIC_OPTION_CODE));

    const [adding, setAdding] = useState(false);

    const addHandler = () => {
        if (variant === undefined) {
            return;
        }

        setAdding(true);

        addItemToOrder.current?.add(1, variant.id).then((result) => {
            switch (result.__typename) {
                case "Order": {
                    sizeSelector.current?.reset();
                    lengthSelector.current?.reset();
                    fabricSelector.current?.reset();
                    break;
                }
                case "InsufficientStockError":
                case "NegativeQuantityError":
                case "OrderLimitError":
                case "OrderModificationError": {
                    toast.open(t("error_add_to_basket"));
                    break;
                }
            }
            setAdding(false);

            addItemToOrder.current?.resetStore();
            openCart?.();
        });
    };

    const consentHandler = () => {
        approve?.();
    };

    return (
        <>
            <AddItemToOrderAction ref={addItemToOrder} />

            <ProductAction ref={productAction} onProducts={handleLoadProduct} />

            <div className="grid">
                <div className="row">
                    <div className="one-half column">
                        {(!isLoading && assets) && <PreviewSlider galleryId={product?.slug} assets={assets} />}
                        {isLoading && <p>Loading images...</p>}
                    </div>

                    <div className="one-half column">
                        <div>
                            <h1 className={["pagetitle", classes.pagetitle].join(" ")}>{product?.name}</h1>

                            <p dangerouslySetInnerHTML={
                                { __html: product?.description || "" }
                            }></p>

                            <p className={classes.articleSlug}>{product?.slug} / {t("article_number")} {product?.id}</p>

                            <SocialSharingComponent product={product} />

                            <div className="row">
                                <div className="column">
                                    {sizeGroup && <>
                                        <h3 className={classes.selection_title}>{t("pick_size")}</h3>
                                        <SizeSelector ref={sizeSelector} options={sizeGroup?.options} onSelected={handleSizeSelected} />
                                    </>}
                                    {lengthGroup && <>
                                        <h3 className={classes.selection_title}>{t("pick_length")}</h3>
                                        <LengthSelector ref={lengthSelector} options={lengthGroup?.options} onSelected={handleLengthSelected} />
                                    </>}
                                    {fabricGroup && <>
                                        <h3 className={classes.selection_title}>{t("pick_fabric")}</h3>
                                        <FabricSelector ref={fabricSelector} options={fabricGroup?.options} onSelected={handleFabricSelected} />
                                    </>}
                                </div>

                                {variant?.priceWithTax && <div className="column" style={{marginLeft: 0}}>
                                    <PriceTag price={variant?.priceWithTax} />
                                </div>}

                                {variant?.priceWithTax && <div className="column" style={{marginLeft: 0, paddingTop: '1em'}}>
                                    <p>{t("time_to_deliver")}</p>
                                </div>}
                            </div>

                            {(variant && consent === true) && <Button 
                                fullWidth={true}
                                loading={adding}
                                onClick={addHandler}>{t("add_to_basket")}</Button>}
                            {(variant && consent !== true) && <Button 
                                fullWidth={true}
                                onClick={consentHandler}>{t("consent_to_cookies")}</Button>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
