import { gql } from "@apollo/client";

export const UpdateCustomerAddressQuery = gql`
mutation UpdateCustomerAddress($input: UpdateAddressInput!) {
    updateCustomerAddress(input: $input) {
        __typename
        streetLine1
        streetLine2
        postalCode
        city
        country {
            code
        }
        defaultBillingAddress
        defaultShippingAddress
    }
}
`;
