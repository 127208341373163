import { createContext } from 'react';
import { Customer } from '../gql/graphql';

interface ActiveCustomerContextInterface {
    customer?: Customer;
}

const defaultState = {
    customer: undefined
};

export const ActiveCustomerContext = createContext<Partial<ActiveCustomerContextInterface>>(defaultState);
