import { gql } from "@apollo/client";

export const EligiblePaymentMethodsQuery = gql`
    query EligiblePaymentMethods {
        eligiblePaymentMethods {
            __typename
            code
            name
            isEligible
            eligibilityMessage
            description
        }
    }
`;
