
import { useTranslation } from "react-i18next";
import { useDrawers, useToast } from "../../hooks";
import { PiXThin } from "react-icons/pi";
import { getFabric, getLength, getSize, priceWithDecimals } from "../helpers";
import { useActiveOrder } from "../../hooks";
import { OrderLineAction, OrderLineRef } from "../../actions/OrderLineAction";
import { useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import classes from "./CartDrawer.module.scss";
import { Order, OrderModificationError } from "../../gql/graphql";
import { useConsent } from "../../hooks/UseConsent";
import { Button } from "../Button";

type Props = {
    open: boolean;
    order?: Order;
};

export default function CartDrawer({ open }: Props) {
    const { t } = useTranslation("cart");

    const { consent, approve } = useConsent();

    const { closeCart } = useDrawers();

    const { order } = useActiveOrder();

    const toast = useToast();

    const orderLineAction = useRef<OrderLineRef>(null);

    const swipeable = useSwipeable({
        onSwipedRight: () => { closeCart?.(); }
    });

    const hasItems = (order?.lines.length ? order?.lines.length > 0 : false);
    
    return (
        <>
            <OrderLineAction ref={orderLineAction} onRemoved={(result) => {
                switch (result.__typename) {
                    case "Order": {
                        if ((result as Order).lines.length <= 0) {
                            closeCart?.();
                        }
                        break;
                    }
                    case "OrderModificationError": {
                        toast.open((result as OrderModificationError).message);
                        break;
                    }
                }
            }} />

            <div className={[classes.container, (open ? classes.open : "")].join(" ")} {...swipeable}>
                <div className={classes.closeBtn}>
                    <PiXThin size="1.8em" onClick={() => {
                        closeCart?.();
                    }} />
                </div>

                <div className={classes.header}>
                    <h3>{t("your_cart")}</h3>
                </div>

                <div className={classes.content}>
                    {order && order.lines.map((line) => (
                        <div className={classes.cartline} key={`order-line-${line.id}`}>
                            {line.featuredAsset && <img className={classes.cartlineimg} src={`${line.featuredAsset.preview}?preset=thumb`} alt={line.productVariant.name} />}
                        
                            <div className={classes.cartlinedetails}>
                                <p>{line.productVariant.name}</p>
                                <p>storlek: {getSize(line.productVariant.options)}, längd: {getLength(line.productVariant.options)}, textil: {getFabric(line.productVariant.options)}</p>
                                <p>Antal: {line.quantity}</p>
                                <p>Pris: {priceWithDecimals(line.linePriceWithTax, t("unit"))}</p>
                            </div>

                            <div className={classes.cartlineactions}>
                                <PiXThin onClick={() => {
                                    orderLineAction.current?.removeOrderLine(line.id);
                                }} />
                            </div>
                        </div>
                    ))}
                    {!hasItems && <p>{t("cart_is_empty")}</p>}
                </div>

                <div className={classes.totals}>
                    <h3>{t("total")}: </h3><h3>{priceWithDecimals(order?.totalWithTax, t("unit"))}</h3>
                </div>

                <p className={classes.shippingnote}>{t("shipping_excluded")}</p>

                {(hasItems === true && consent === true) && <Link to="/checkout" onClick={() => {closeCart?.();}}>
                    <button className={classes.checkoutBtn}>{t("continue_to_checkout")}</button>
                </Link>}
                {(hasItems === true && consent !== true) && <Button 
                    fullWidth={true}
                    onClick={() => {
                        approve?.();
                    }}>{t("consent_to_cookies")}</Button>}
                {(hasItems === false) && <p>{t("add_items_to_continue")}</p>}
            </div>
        </>
    );
}
