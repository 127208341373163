import { gql } from "@apollo/client";

export const DeleteCustomerAddressQuery = gql`
mutation DeleteCustomerAddress($id: ID!) {
    deleteCustomerAddress(id: $id) {
        __typename
        success
    }
}
`;
