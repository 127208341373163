import { Link } from 'react-router-dom';
import classes from './Footertest.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { PublicDataAction, PublicDataRef } from '../../actions/PublicDataAction';
import { CiFacebook, CiInstagram } from "react-icons/ci";
import { RiPinterestLine } from "react-icons/ri";
import { BiLogoEtsy } from 'react-icons/bi';
import { FaCcMastercard, FaCcVisa, FaStripe } from 'react-icons/fa6';
import { printAddress } from '../helpers';
import { Address } from '../../gql/graphql';

export default function Footer() {

    const { t } = useTranslation("footer");

    const globalSettingsAction = useRef<PublicDataRef>(null);

    const [loaded, setLoaded] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);

    const [address, setAddress] = useState<Address | null>();

    const [openHours, setOpenHours] = useState<string | null>(null);
    const [phone, setPhone] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);

    const [instagramUri, setInstagramUri] = useState<string | null>(null);
    const [facebookUri, setFacebookUri] = useState<string | null>(null);
    const [pinterestUri, setPinterestUri] = useState<string | null>(null);
    const [etsyUri, setEtsyUri] = useState<string | null>(null);

    const [affiliates, setAffiliates] = useState<Array<string> | null>(null);
    const [affiliatesTitles, setAffiliatesTitles] = useState<Array<string> | null>(null);

    useEffect(() => {
        if (!loaded) {
            setLoading(true);

            globalSettingsAction.current?.get().then((result) => {
                setLoading(false);

                const {
                    streetAddress,
                    postal,
                    zip,

                    openHours,
                    phone,
                    email,

                    instagramUri,
                    facebookUri,
                    pinterestUri,
                    etsyUri,

                    affiliates,
                    affiliates_titles
                } = result;

                const address = {
                    fullName: "Veras Ateljé",
                    streetLine1: streetAddress,
                    postalCode: zip,
                    city: postal
                } as unknown;
                setAddress(address as Address);

                setOpenHours(openHours ? openHours : null);
                setPhone(phone ? phone : null);
                setEmail(email ? email : null);

                setInstagramUri(instagramUri ? instagramUri : null);
                setFacebookUri(facebookUri ? facebookUri : null);
                setPinterestUri(pinterestUri ? pinterestUri : null);
                setEtsyUri(etsyUri ? etsyUri : null);

                if (affiliates) {
                    setAffiliates(affiliates ? affiliates : null);
                }
                setAffiliatesTitles(affiliates_titles ? affiliates_titles : null);
            });
            setLoaded(true);
        }
    }, [loaded]);

    return (
        <>
            <PublicDataAction ref={globalSettingsAction} />

            <footer className={classes.footer}>
                <div className={classes.wrapper}>
                    <section itemScope itemType="https://schema.org/LocalBusiness">
                        <h3 itemProp="legalName" className={classes.header}>Veras Ateljé</h3>
                        <span itemScope>
                            <span className='bold'>{t("address")}:</span>
                            {address && printAddress(address)}
                            {/* <address itemScope itemType="https://schema.org/PostalAddress">
                                {streetAddress && <><span itemProp="streetAddress">{streetAddress}</span><br /></>}
                                {zip && <><span itemProp="addressZip">{zip}</span><br /><br /></>}
                                {postal && <><span itemProp="addressLocality">{postal}</span><br /><br /></>}
                            </address> */}

                            <address itemScope itemType="https://schema.org/PostalAddress">
                                <><span itemProp="streetAddress">Boråsvägen 237</span><br /></>
                                <><span itemProp="addressZip">511 70</span><br /><br /></>
                                <><span itemProp="addressLocality">Rydal</span><br /><br /></>
                            </address>
                        </span>
                        {openHours && <p>
                            <meta itemProp="openingHours" content={openHours} />
                            <span className='bold'>{t("open_hours")}:</span>
                            <span>{openHours}</span>
                        </p>}
                        {phone && <p>
                            <span className='bold'>{t("phone")}:</span>
                            <span><a href={`tel:${phone}`} itemProp="telephone">{phone}</a></span>
                        </p>}
                        {email && <p>
                            <span className='bold'>{t("email")}:</span>
                            <span><a href={`mailto:${email}`} itemProp="email">{email}</a></span>
                        </p>}
                    </section>

                    <section>
                        <h3 className={classes.header}>{t("navigation")}</h3>
                        <p>
                            <Link to='/privacypolicy' itemProp="url">
                                {t("privacy_policy")}
                            </Link>
                        </p>
                        <p>
                            <Link to="terms" itemProp="url">
                                {t("terms")}
                            </Link>
                        </p>
                    </section>

                    <section>
                        <h3 className={classes.header}>{t("links")}</h3>
                        {affiliates && affiliates.map((affiliate, index) => 
                            <p itemScope key={`affiliate-${index}`}>
                                <a href={`https://${affiliate}`} target="_blank" rel="noreferrer" itemProp="url">
                                    {affiliatesTitles && affiliatesTitles[index] ? affiliatesTitles[index] : affiliate}
                                </a>
                            </p>
                        )}
                        <div className={classes.socialContainer}>
                            {instagramUri && <p itemScope>
                                <a href={`https://${instagramUri}`} target="_blank" rel="noreferrer">
                                    <CiInstagram size="2em" />
                                </a>
                            </p>}
                            {facebookUri && <p itemScope>
                                <a href={`https://${facebookUri}`} target="_blank" rel="noreferrer">
                                    <CiFacebook size="2em" />
                                </a>
                            </p>}
                            {pinterestUri && <p itemScope>
                                <a href={`https://${pinterestUri}`} target="_blank" rel="noreferrer">
                                    <RiPinterestLine size="2em" />
                                </a>
                            </p>}
                            {etsyUri && <p itemScope>
                                <a href="https://www.etsy.com/se-en/shop/VerasAteljeSweden" target="_blank" rel="noreferrer">
                                    <BiLogoEtsy size="2em" />
                                </a>
                            </p>}
                        </div>
                    </section>

                    <section>
                        <h3 className={classes.header}>{t("payment_options")}</h3>
                        <div className={classes.cardcontainer}>
                            <FaCcVisa size="3em" />
                            <FaCcMastercard size="3em" />
                        </div>
                        <div className={classes.paymenthandlercontainer}>
                            <FaStripe size="5em" />
                        </div>
                    </section>
                </div>
            </footer>
        </>
    );
}
