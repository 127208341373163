export const changesSV = [
    { title: "Lägga upp brud- eller balklänning", price: "800 till 1500 kr." },
    { title: "Flera ändringar på brud- eller balklänning", price: "1500 till 3000 kr." },
    { title: "Flera ändringar på kostym", price: "c:a 1500 kr." },
    { title: "Ta in benvidd", price: "600 kr." }
];

export const bespokeSV = [
    { title: "Cocktail- eller balklänning (exkl. kostnad för material)", price: "Från 6000 kr." },
    { title: "Brudklänning (beroende på modell)", price: "6000 till 12000 kr." },
    { title: "Enkel klänning t.ex. linne eller bomull (exkl. kostnad för material)", price: "2000 till 5000 kr." },
    { title: "Kjol", price: "1000 till 3000 kr." },
    { title: "Kappa", price: "6000 till 12000 kr." },
    { title: "Klassisk damskjorta", price: "2500 till 3000 kr." },
    { title: "Damkavaj; beroende på metod", price: "" },
    { title: "Handsydd kavaj (made to measure) med handgjorda knapphål", price: "10000 till 15000 kr." },
    { title: "Enklare kavaj", price: "6000 till 8000 kr." }
];

export const changesEN = [
    { title: "Putting up a wedding or prom dress", price: "800 to 1500 SEK" },
    { title: "Several changes to wedding or prom dress", price: "1500 to 3000 SEK" },
    { title: "Several changes to costume", price: "c:a 1500 SEK" },
    { title: "Take in leg width", price: "600 SEK" }
];

export const bespokeEN = [
    { title: "Cocktail or prom dress (excl. cost of materials)", price: "From 6000 SEK" },
    { title: "Wedding dress (depending on model)", price: "6000 to 12000 SEK" },
    { title: "Simple dress e.g. linen or cotton (excl. cost of material)", price: "2000 to 5000 SEK" },
    { title: "Skirt", price: "1000 to 3000 SEK" },
    { title: "Coat", price: "6000 to 12000 SEK" },
    { title: "Classic women's shirt", price: "2500 to 3000 SEK" },
    { title: "Women's blazer; depending on the method", price: "" },
    { title: "Bespoke jacket (made to measure) with hand-made buttonholes", price: "10000 to 15000 SEK" },
    { title: "Simpler jacket", price: "6000 to 8000 SEK" }
];
