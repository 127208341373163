import { renderForm } from "@agoransson/formhelper";
import React, { Ref, createRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActiveOrderResult, Customer } from "../../../gql/graphql";
import { OrderAction, OrderRef } from "../../../actions/OrderAction";

const COUNTRY_CODE_SE = "SE";

type Props = {
    formType: FormType,
    customer?: Customer | undefined;
    onFormReady?: (ready: boolean) => void;
}

export enum FormType {
    SHIPPING,
    BILLING
}

export interface FormRef {
    submit: () => Promise<ActiveOrderResult>;
}

function AddressInputForm(
    { formType, customer, onFormReady }: Props,
    ref: Ref<FormRef>
) {
    const orderAction = useRef<OrderRef>(null);

    const { t } = useTranslation("checkout");

    let formRef = createRef<HTMLFormElement>();

    // Used only for logged in customers
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");

    // Used for both logged in and guest customers
    const [streetLine1, setStreetLine1] = useState<string>("");
    const [streetLine2, setStreetLine2] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [countryCode] = useState<string>(COUNTRY_CODE_SE);

    useEffect(() => {
        if (customer) {
            setFirstName(customer?.firstName);
            setLastName(customer?.lastName);
            if (customer.addresses && customer.addresses.length > 0) {
                console.log(customer.addresses);
            }
        }
    }, [customer]);

    const handleFirstName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(evt.currentTarget.value);
    };

    const handleLastName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(evt.currentTarget.value);
    };

    const handleStreetLine1 = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setStreetLine1(evt.currentTarget.value);
    };

    const handleStreetLine2 = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setStreetLine2(evt.currentTarget.value);
    };

    const handlePostalCode = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setPostalCode(evt.currentTarget.value);
    };

    const handleCity = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setCity(evt.currentTarget.value);
    };

    const customerForm = [
        [
            {type: 'text', name: 'firstName', placeholder: t("first_name"), onChange: handleFirstName, value: firstName},
            {type: 'text', name: 'lastName', placeholder: t("last_name"), onChange: handleLastName, value: lastName}
        ]
    ];

    const addressForm = [
        [
            {type: 'text', name: 'streetLine1', placeholder: t("street"), onChange: handleStreetLine1, value: streetLine1},
        ],
        [
            {type: 'text', name: 'streetLine2', placeholder: t("extra_street"), onChange: handleStreetLine2, value: streetLine2},
        ],
        [
            {type: 'text', name: 'postalCode', placeholder: t("zip"), onChange: handlePostalCode, value: postalCode},
            {type: 'text', name: 'city', placeholder: t("city"), onChange: handleCity, value: city},
        ]
    ];

    const composedForm = [...customerForm, ...addressForm];

    const submit = (): Promise<ActiveOrderResult> => {
        return new Promise<ActiveOrderResult>((resolve, reject) => {
            if (!firstName || !lastName || !streetLine1 || !postalCode || !city) {
                reject(t("missing_required_data"));
            } else  if (orderAction.current) {
                const input = {
                    fullName: `${firstName} ${lastName}`,
                    streetLine1,
                    streetLine2,
                    postalCode,
                    city,
                    countryCode
                };

                if (formType === FormType.SHIPPING) {
                    orderAction.current?.setShipping(input).then((result) => {
                        resolve(result);
                    }).catch((reason) => {
                        reject(reason);
                    });
                } else if (formType === FormType.BILLING) {
                    orderAction.current?.setBilling(input).then((result) => {
                        resolve(result);
                    }).catch((reason) => {
                        reject(reason);
                    });
                } else {
                    reject(t("form_type_error"))
                }
            } else {
                reject(t("client_error"));
            }
        });
    };

    useEffect(() => {
        const isNotReady = !firstName || !lastName || !streetLine1 || !postalCode || !city;

        if (isNotReady) {
            return;
        }

        onFormReady?.(!isNotReady);
    }, [firstName, lastName, streetLine1, postalCode, city, onFormReady]);

    useImperativeHandle(ref, () => ({ submit }));

    return (
        <>
            <OrderAction ref={orderAction} />

            <form style={{ marginBottom: '0'}} ref={formRef}>
                <fieldset className="fieldset" style={{ paddingBottom: '0', marginBottom: '0', paddingTop: '0'}}>
                    {renderForm(composedForm)}
                </fieldset>
            </form>
        </>
    );
}

export default React.forwardRef(AddressInputForm);
