import { useMutation } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { AddItemToOrderQuery } from ".";
import { UpdateOrderItemsResult } from "../../gql/graphql";

type Props = {
}

export interface AddItemToOrderRef {
    add: (quantity: number, productVariantId: string) => Promise<UpdateOrderItemsResult>,
    resetStore: () => void
}

function AddItemToOrderAction (
    props: Props,
    ref: Ref<AddItemToOrderRef>
) {
    const [mutate, { client }] = useMutation<{addItemToOrder: UpdateOrderItemsResult}>(AddItemToOrderQuery);

    const add = (quantity: number, productVariantId: string): Promise<UpdateOrderItemsResult> => (
        new Promise<UpdateOrderItemsResult>((resolve, reject) => {
            mutate({
                variables: {
                    productVariantId,
                    quantity
                },
                onCompleted: ({ addItemToOrder }) => {
                    resolve(addItemToOrder);
                },
                onError: (reason) => {
                    reject(reason);
                }
            })
        })
    );

    const resetStore = () => {
        client.resetStore();
    }

    useImperativeHandle(ref, () => ({ add, resetStore }));

    return <></>;
}

export default React.forwardRef(AddItemToOrderAction);
