import { renderForm } from "@agoransson/formhelper";
import { useEffect, useRef, useState } from "react";
import { VerifyAction, VerifyRef } from "../../actions/VerifyAction";
import { MissingPasswordError, NativeAuthStrategyError, PasswordAlreadySetError, PasswordValidationError, VerificationTokenExpiredError, VerificationTokenInvalidError } from "../../gql/graphql";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";

type Props = {
    defaultToken?: string | null;
    onVerified: () => void;
    onError: () => void;
}

const MINIMUM_PASSWORD_LENGTH = 4;

export default function VerifyForm({ defaultToken, onVerified, onError }: Props) {

    const { t } = useTranslation("verify");

    const verifyAction = useRef<VerifyRef>(null);

    const [loading, setLoading] = useState(false);
    const [verifyToken, setVerifyToken] = useState<string>("");
    const [password1, setPassword1] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (defaultToken && !verifyToken) {
            setVerifyToken(defaultToken);
        }
    }, [defaultToken, verifyToken]);

    const handleVerifyChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setVerifyToken(evt.currentTarget.value);
    };

    const handlePassword1 = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setPassword1(evt.currentTarget.value);
    };

    const handlePassword2 = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setPassword2(evt.currentTarget.value);
    };

    const verifyForm = [
        {type: 'text', name: 'verify', placeholder: t("verification_token"), value: verifyToken, onChange: handleVerifyChanged, disabled: loading},
        [
            {type: 'password', name: 'password', placeholder: t("password"), value: password1, onChange: handlePassword1, disabled: loading},
            {type: 'password', name: 'password2', placeholder: t("password2"), value: password2, onChange: handlePassword2, disabled: loading}
        ]
    ];

    const verifyHandler = () => {
        if (!verifyToken || verifyToken.length <= 0) {
            setError(t("missing_verify_token"));
            return;
        }
        if ((!password1 || password1.length <= MINIMUM_PASSWORD_LENGTH) || !password2 || password2.length <= MINIMUM_PASSWORD_LENGTH) {
            setError(t("password_length_incorrect"));
            return;
        }
        if (password1 !== password2) {
            setError(t("passwords_incorrect"));
            return;
        }

        setLoading(true);

        verifyAction.current?.verify(verifyToken, password1).then((result) => {
            setLoading(false);

            switch(result.__typename) {
                case "CurrentUser": {
                    onVerified();
                    break;
                }
                case "MissingPasswordError": {
                    setError((result as MissingPasswordError).message);
                    break;
                }
                case "NativeAuthStrategyError": {
                    setError((result as NativeAuthStrategyError).message);
                    break;
                }
                case "PasswordAlreadySetError": {
                    setError((result as PasswordAlreadySetError).message);
                    break;
                }
                case "PasswordValidationError": {
                    setError((result as PasswordValidationError).message);
                    break;
                }
                case "VerificationTokenExpiredError": {
                    setError((result as VerificationTokenExpiredError).message);
                    break;
                }
                case "VerificationTokenInvalidError": {
                    setError((result as VerificationTokenInvalidError).message);
                    break;
                }
            }
        });
    };

    return (
        <>
            <VerifyAction ref={verifyAction} />

            <form>
                <fieldset className="fieldset">
                    <legend>Verifiera kundprofil</legend> 
                    {renderForm(verifyForm)}
                    {error && <p className="formError">{error}</p>}

                    <Button
                        fullWidth={true}
                        loading={loading}
                        onClick={verifyHandler}>{loading ? t("verifying") : t("verify_account")}</Button>
                </fieldset>
            </form>
        </>
    );
}
