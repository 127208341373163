import { useLazyQuery } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { ProductQuery } from "./ProductQuery";
import { ProductsQuery } from "./ProductsQuery";
import { Product } from "../../gql/graphql";

export interface ProductRef {
    get: (slug: string) => void,
    list: (slug: string) => void,
    resetStore: () => void
}

function ProductAction (
    props: {onProducts: (items: Array<Product>) => void},
    ref: Ref<ProductRef>
) {
    const { onProducts } = props;

    const [getQuery, { client }] = useLazyQuery<{product: Product}>(ProductQuery, {
        fetchPolicy: "network-only",
        onCompleted: ({ product }) => {
            onProducts([product]);
        }
    });

    const [listQuery] = useLazyQuery<{items: Array<Product>, totalItems: number}>(ProductsQuery, {
        fetchPolicy: "network-only",
        onCompleted: ({ items }) => {
            onProducts(items);
        }
    });

    const get = (slug: string): void => {
        getQuery({
            variables: {
                slug
            }
        });
    }

    const list = (slug: string): void => {
        listQuery();
    }

    const resetStore = () => {
        if (!client) {
            return;
        }

        client.resetStore();
    }

    useImperativeHandle(ref, () => ({ get, list, resetStore }));

    return <></>;
}

export default React.forwardRef(ProductAction);

