import React, { useRef, useState } from "react";
import { renderForm } from '@agoransson/formhelper';
import { useTranslation } from "react-i18next";
import { AccountAction, AccountRef } from "../../actions/AccountAction";
import { NativeAuthStrategyError } from "../../gql/graphql";
import { Button } from "../Button";
import { Link } from "react-router-dom";

const CUSTOMER_PAGE = "/customer";

type Props = {
    defaultEmail: string | null;
};

export default function RequestPasswordResetForm({ defaultEmail }: Props) {

    const { t } = useTranslation("password_reset");

    const accountAction = useRef<AccountRef>(null);

    const [loading, setLoading] = useState(false);
    const [emailAddress, setEmailAddress] = useState<string | null>(defaultEmail);
    const [error, setError] = useState<string | undefined>();
    const [success, setSuccess] = useState(false);

    const handleEmailAddress = (value: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(value.currentTarget.value);
    }

    const loginForm = [
        [
            {type: 'email', name: 'emailAddress', placeholder: t("email"), onChange: handleEmailAddress, disabled: loading, required: true},
        ]
    ];

    const requestPasswordHandler = () => {
        if (!emailAddress) {
            setError(t("missing_email_address"));
            return;
        }

        setLoading(true);
        setError(undefined);

        accountAction.current?.requestPasswordReset(emailAddress).then((result) => {
            setLoading(false);

            switch (result.__typename) {
                case "NativeAuthStrategyError": {
                    setError((result as NativeAuthStrategyError).message);
                    break;
                }
                case "Success": {
                    setSuccess(true);
                    accountAction.current?.resetStore();
                    break;
                }
            }
        });
    }

    return (
        <>
            <AccountAction ref={accountAction} />

            <form>
                <fieldset className="fieldset">
                    {renderForm(loginForm)}
                    {error && <p className="formError">{error}</p>}

                    <Button
                        fullWidth={true}
                        disabled={success}
                        loading={loading}
                        onClick={requestPasswordHandler}>{loading ? t("requesting_reset") : t("request_reset_password")}</Button>
                </fieldset>
            </form>

            {success && <Link to={CUSTOMER_PAGE} />}
        </>
    );
}
