import React, { useRef, useState } from "react";
import { renderForm } from '@agoransson/formhelper';
import { useTranslation } from "react-i18next";
import { AccountAction, AccountRef } from "../../actions/AccountAction";
import { useNavigate } from "react-router-dom";
import { NativeAuthStrategyError, NotVerifiedError, PasswordResetTokenExpiredError, PasswordResetTokenInvalidError, PasswordValidationError } from "../../gql/graphql";


const CUSTOMER_PAGE = "/customer";

type Props = {
    defaultToken: string | null;
};

export default function PasswordResetForm({ defaultToken }: Props) {

    const { t } = useTranslation("password_reset");

    const navigate = useNavigate();

    const accountAction = useRef<AccountRef>(null);

    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState<string | null>(defaultToken);
    const [password1, setPassword1] = useState<string | null>();
    const [password2, setPassword2] = useState<string | null>();
    const [error, setError] = useState<string | undefined>();

    const handleToken = (value: React.ChangeEvent<HTMLInputElement>) => {
        setToken(value.currentTarget.value);
    }

    const handlePassword1 = (value: React.ChangeEvent<HTMLInputElement>) => {
        setPassword1(value.currentTarget.value);
    }

    const handlePassword2 = (value: React.ChangeEvent<HTMLInputElement>) => {
        setPassword2(value.currentTarget.value);
    }

    const handleSubmit = (evt: React.FormEvent) => {
        evt.preventDefault();

        if (!token || !password1 || !password2) {
            return;
        }

        if (password1 !== password2) {
            return
        }

        setLoading(true);
        setError(undefined);

        accountAction.current?.resetPassword(token, password1);
    }

    const resetPasswordForm = [
        [
            {type: 'text', name: 'token', placeholder: t("token"), value: token, onChange: handleToken, disabled: loading, required: true},
        ],
        [
            {type: 'password', name: 'password1', placeholder: t("password1"), value: password1, onChange: handlePassword1, disabled: loading, required: true},
            {type: 'password', name: 'password2', placeholder: t("password2"), value: password2, onChange: handlePassword2, disabled: loading, required: true},
        ],
        [
            {type: 'submit', title: loading ? t("resetting_password") : t("reset_password"), disabled: loading}
        ]
    ];

    return (
        <>
            <AccountAction ref={accountAction} onResetPassword={(result) => {
                setLoading(false);

                switch (result.__typename) {
                    case "CurrentUser": {
                        accountAction.current?.resetStore();
                        navigate(CUSTOMER_PAGE);
                        break;
                    }
                    case "NativeAuthStrategyError": {
                        setError((result as NativeAuthStrategyError).message);
                        break;
                    }
                    case "NotVerifiedError": {
                        setError((result as NotVerifiedError).message);
                        break;
                    }
                    case "PasswordResetTokenExpiredError": {
                        setError((result as PasswordResetTokenExpiredError).message);
                        break;
                    }
                    case "PasswordResetTokenInvalidError": {
                        setError((result as PasswordResetTokenInvalidError).message);
                        break;
                    }
                    case "PasswordValidationError": {
                        setError((result as PasswordValidationError).message);
                        break;
                    }
                }
            }} />

            <form onSubmit={handleSubmit}>
                <fieldset className="fieldset">
                    {renderForm(resetPasswordForm)}
                    {error && <p className="formError">{error}</p>}
                </fieldset>
            </form>
        </>
    );
}
