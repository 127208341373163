import { gql } from "@apollo/client";
import { AssetFragment, ProductFragment, VariantsFragment, OptionGroupsFragment } from "./ProductFragment";

export const ProductQuery = gql`
    ${ProductFragment}
    ${AssetFragment}
    ${VariantsFragment}
    ${OptionGroupsFragment}
    query Product($slug: String!) {
        product(slug: $slug) {
            __typename
            languageCode
            ...ProductFragment
            ...AssetFragment
            ...VariantsFragment
            ...OptionGroupsFragment
            featuredAsset {
                source
            }
        }
    }
`;
