import { gql } from "@apollo/client";

export const SetCustomerForOrderQuery = gql`
mutation SetCustomerForOrder($input: CreateCustomerInput!) {
    setCustomerForOrder(input: $input) {
        ... on Order {
            __typename
            id
            code
            shippingAddress {
                fullName
                company
                streetLine1
                streetLine2
                city
                province
                postalCode
                country
                countryCode
                phoneNumber
                customFields
            }
        }
        ... on AlreadyLoggedInError {
            __typename
            message
        }
        ... on EmailAddressConflictError {
            __typename
            message
        }
        ... on NoActiveOrderError {
            __typename
            message
        }
        ... on GuestCheckoutError {
            __typename
            message
        }
    }
}
`;
