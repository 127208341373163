import { useRef } from "react";
import { Accordion, AccordionRef } from "../../components/Accordion";
import { CustomerRow } from "./Customer";
import { AddressRow } from "./Address";
import { ShippingRow } from "./Shipping";
import { PaymentsRow } from "./Payments";
import { useActiveOrder } from "../../hooks";
import { Customer } from "../../gql/graphql";

type Props = {
    customer?: Customer | undefined
};

export default function CheckoutAccordion({ customer }: Props) {
    const accordion = useRef<AccordionRef>(null);

    const { order } = useActiveOrder();

    const handlePrevious = () => accordion.current?.previous();
    const handleNext = () => accordion.current?.next();
    
    // This is a way to declare some non-undefined object that is not a boolean
    // when using conditional rendering. That way I don't need to implement a
    // lot of extra logic in Accordion component to handle special types.
    const guestChckout = customer === undefined ? {} : undefined;

    return (
        <Accordion ref={accordion}>
            {guestChckout && <CustomerRow
                orderId={order?.id}
                onNext={handleNext}
                onOpened={() => {
                    console.log('CustomerRow opened');
                }}
                onClosed={() => {
                    console.log('CustomerRow closed');
                }} 
                />}

            <ShippingRow
                onPrevious={handlePrevious}
                onNext={handleNext}
                onOpened={() => {
                    console.log('ShippingRow opened');
                }}
                onClosed={() => {
                    console.log('ShippingRow closed');
                }} />

            <AddressRow
                customer={customer}
                onPrevious={(customer === undefined) ? handlePrevious : undefined}
                onNext={handleNext}
                onOpened={() => {
                    console.log('AddressRow opened');
                }}
                onClosed={() => {
                    console.log('AddressRow closed');
                }} />

            <PaymentsRow
                onPrevious={handlePrevious}
                onOpened={() => {
                    console.log('PaymentsRow opened');
                }}
                onClosed={() => {
                    console.log('PaymentsRow closed');
                }} />
        </Accordion>
    );
}
