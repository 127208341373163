import { Link } from "react-router-dom";
import classes from './OrdersList.module.scss';
import { useTranslation } from "react-i18next";
import { Customer, Order } from "../../gql/graphql";

type Props = {
    customer: Customer;
}

export default function OrdersList({ customer }: Props) {

    const { t } = useTranslation("orders");

    const { orders } = customer;
    const { items } = orders;

    return (
        <>
            <fieldset className="fieldset">
                <legend>{t("placed_orders")}</legend>
                {items.length > 0 && <ul>
                    {(items as Order[]).map((order) => (
                        <li key={order.id}>
                            <Link className={classes.link} to={`/customer/order/${order.code}`}>
                                {order.code} - {order.state}
                            </Link>
                        </li>
                    ))}
                </ul>}
                {customer.orders.items.length <= 0 && <p>{t("no_placed_orders")}</p>}
            </fieldset>
        </>
    );
}
