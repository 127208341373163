import { gql } from "@apollo/client";

export const TransitionOrderToStateQuery = gql`
mutation TransitionOrderToState($state: String!) {
    transitionOrderToState(state: $state) {
        ... on Order {
            __typename
            id
            createdAt
            total
            customFields
        }
        ... on OrderStateTransitionError {
            __typename
            message
            transitionError
            fromState
            toState
        }
    }
}
`;
