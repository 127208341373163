import SelectorItem from "./SelectorItem";
import classes from "./PaymentMethodSelector.module.scss";
import StripeItem from "./StripeItem";
import { StripePaymentAction, StripePaymentRef } from "../../../actions/StripeAction";
import { useEffect, useRef, useState } from "react";
import { PaymentMethodQuote } from "../../../gql/graphql";
import { PaymentMethodAction, PaymentMethodRef } from "../../../actions/PaymentMethodAction";
import { useActiveOrder } from "../../../hooks";

type Props = {
    isOpen?: boolean;
    onReady?: (ready: boolean) => void;
};

const STRIPE_CARD_PAYMENT_CODE = "stripe-card-payment";

function PaymentMethodSelector(
    { isOpen, onReady }: Props
) {
    const stripeAction = useRef<StripePaymentRef>(null);
    const paymentMethodAction = useRef<PaymentMethodRef>(null);

    const [loaded, setLoaded] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodQuote[]>();
    const [selectedPayment, setSelectedPayment] = useState<PaymentMethodQuote>();
    const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);

    const { order } = useActiveOrder();

    useEffect(() => {
        if (!loaded && isOpen) {
            paymentMethodAction.current?.get().then((result) => {
                setPaymentMethods(result);
            })
            setLoaded(true);
        }
    }, [isOpen, loaded, setLoaded]);

    useEffect(() => {
        onReady?.(selectedPayment !== undefined);
    }, [selectedPayment, onReady]);

    useEffect(() => {
        if (isOpen && paymentMethods && paymentMethods.length > 0) {
            setSelectedPayment(paymentMethods[0]);
        }
    }, [isOpen, paymentMethods])

    useEffect(() => {
        if (!isOpen) {
            return;
        } else {
            if (clientSecret == null) {
                stripeAction.current?.create();
            }
        }
    }, [isOpen, clientSecret]);

    return (
        <>
            <PaymentMethodAction ref={paymentMethodAction} />

            <StripePaymentAction ref={stripeAction} onCompleted={(clientSecret) => {
                setClientSecret(clientSecret);
            }}/>

            <div className={classes.container}>
                {paymentMethods?.map((paymentMethod) => {
                    if (paymentMethod.code === STRIPE_CARD_PAYMENT_CODE ) {
                        return (
                            <StripeItem key={paymentMethod.code} {...{orderCode: order?.code, paymentMethod, selected: selectedPayment?.code === paymentMethod.code, clientSecret}} onClick={() => {
                                if (clientSecret == null) {
                                    stripeAction.current?.create();
                                }
                                
                                setSelectedPayment(paymentMethod);
                            }} />
                        );
                    } else {
                        return (
                            <SelectorItem key={paymentMethod.code} {...{paymentMethod, selected: selectedPayment?.code === paymentMethod.code}} onClick={() => {
                                setSelectedPayment(paymentMethod);
                            }} />
                        );
                    }
                })}
            </div>
        </>
        
    );
}

export default PaymentMethodSelector;
