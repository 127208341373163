import { useTranslation } from "react-i18next";
import { priceWithDecimals } from "../helpers";
import classes from "./CartSummary.module.scss";
import { Namespace, TFunction } from "i18next";
import { Customer, Order } from "../../gql/graphql";

const getContinueText = (customer: Customer | undefined, order: Order | undefined, t: TFunction<Namespace, undefined>) => {
    if (!customer) {
        return t("login_to_proceed");
    } else if ((order?.lines?.length || 0) <= 0) {
        return t("add_an_item_to_continue");
    } else {
        return t("continue_to_checkout");
    }
}

type Props = {
    customer?: Customer;
    order?: Order;
    onContinue?: () => void;
}

export default function CartSummary({
    order,
    customer,
    onContinue
}: Props) {

    const { t } = useTranslation("cart");

    const showContinueButton = customer && (customer.emailAddress) && (order?.lines.length || 0) > 0;

    const continueText = getContinueText(customer, order, t);

    const subTotal = order === undefined ? 0 : order?.subTotal;
    const shipping = order === undefined ? 0 : order?.shipping;
    const total = order === undefined ? 0 : order?.total;
    const totalWithTax = order === undefined ? 0 : order?.totalWithTax;

    const VAT = totalWithTax - total;

    return (
        <>
            <div style={{width: "100%"}}>
                <table style={{width: "100%"}}>
                    <tbody>
                        <tr>
                            <td><h4 className={classes.heading}>{t("subtotal")}</h4></td>
                            <td style={{textAlign: "right"}}>
                                <p className={classes.text}>{priceWithDecimals(subTotal, t("unit"))}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <h4 className={classes.heading}>{t("shipping_no_vat")}</h4>
                                </div>
                            </td>
                            <td style={{textAlign: "right"}}>
                                <p className={classes.text}>{priceWithDecimals(shipping, t("unit"))}</p>
                            </td>
                        </tr>
                        <tr>
                            <td><h4 className={classes.heading}>{t("vat")}</h4></td>
                            <td style={{textAlign: "right"}}>
                                <div style={{display: "flex", justifyContent: "end"}}>
                                    <p className={classes.text}>{priceWithDecimals(VAT, t("unit"))}</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <hr className={classes.hr} />
                            </td>
                        </tr>
                        <tr>
                            <td><h4 className={[classes.heading, classes.fat].join(" ")}>{t("total")}</h4></td>
                            <td style={{textAlign: "right"}}>
                                <p className={[classes.text, classes.fat].join(" ")}>{priceWithDecimals(totalWithTax, t("unit"))}</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <hr className={classes.hr} />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {onContinue && <button className={[classes.button, (showContinueButton ? '' : classes.disabled)].join(' ')} onClick={() => {
                    if (showContinueButton) {
                        onContinue();
                    }
                }}>{continueText}</button>}
            </div>
        </>
    );
}
