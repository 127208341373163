import { Navigate } from "react-router-dom";
import { useActiveCustomer } from "../../hooks";

type Props = {
    children: JSX.Element[] | JSX.Element,
};

export default function PublicRoute(props: Props) {
    const { children } = props;

    const { customer } = useActiveCustomer();
    
    if (customer) {
        return <Navigate to="/customer" replace />
    }

    return (
        <>
            {children}
        </>        
    );
}
