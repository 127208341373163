import { useTranslation } from "react-i18next";
import { Button } from "../Button";

type AccordionButtonState = {
    isReady: boolean;
};

export type AccordionButtonInterface = {
    onPrevious?: () => void;
    onNext?: () => void;
};

export type AccordionButtonProps = AccordionButtonInterface & AccordionButtonState;

export default function AccordionButtons({ isReady, onPrevious, onNext }: AccordionButtonProps) {
    const { t } = useTranslation("checkout");
    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                {onPrevious && <Button onClick={() => { onPrevious?.(); }} style={{marginTop: '1em'}}>{t("go_back")}</Button>}
                {onNext && <Button disabled={!isReady} onClick={() => { onNext?.(); }} style={{marginTop: '1em'}}>{t("go_forward")}</Button>}
            </div>
        </>
    );
}