import { useRef, useState } from "react";
import { renderForm } from "@agoransson/formhelper";
import { CustomerAddressAction, CustomerAddressRef } from "../../actions/CustomerAddressAction";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import { Address } from "../../gql/graphql";

const COUNTRY_CODE_SE = "SE";

type Props = {
    address: Address;
    onDelete: (id: string) => void;
}

export default function AddressForm({ address, onDelete }: Props) {

    const { t } = useTranslation("address");

    const customerAddressAction = useRef<CustomerAddressRef>(null);

    const [loading, setLoading] = useState(false);

    const [id] = useState<string>(address.id);
    const [streetLine1, setStreetLine1] = useState<string>(address.streetLine1);
    const [streetLine2, setStreetLine2] = useState<string>(address?.streetLine2 ? address?.streetLine2 : "");
    const [postalCode, setPostalCode] = useState<string>(address.postalCode ? address.postalCode : "");
    const [city, setCity] = useState<string>(address.city ? address.city : "");
    const [countryCode] = useState<string>(COUNTRY_CODE_SE);
    const [defaultShippingAddress, setDefaultShippingAddress] = useState<Boolean>(address?.defaultShippingAddress ? address?.defaultShippingAddress : false);
    const [defaultBillingAddress, setDefaultBillingAddress] = useState<Boolean>(address?.defaultBillingAddress ? address?.defaultBillingAddress : false);

    const [error] = useState();

    const handleStreetLine1 = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setStreetLine1(evt.currentTarget.value);
    };

    const handleStreetLine2 = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setStreetLine2(evt.currentTarget.value);
    };

    const handlePostalCode = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setPostalCode(evt.currentTarget.value);
    };

    const handleCity = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setCity(evt.currentTarget.value);
    };

    const handleDefaultShippingAddress = (value: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultShippingAddress(value.currentTarget.checked);
    };

    const handleDefaultBillingAddress = (value: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultBillingAddress(value.currentTarget.checked);
    };

    const addressForm = [
        [
            {type: 'text', name: 'streetLine1', placeholder: t("street"), value: streetLine1, onChange: handleStreetLine1, disabled: loading},
        ],
        [
            {type: 'text', name: 'streetLine2', placeholder: t("extra_street"), value: streetLine2, onChange: handleStreetLine2, disabled: loading},
        ],
        [
            {type: 'text', name: 'postalCode', placeholder: t("zip"), value: postalCode, onChange: handlePostalCode, disabled: loading},
            {type: 'text', name: 'city', placeholder: t("city"), value: city, onChange: handleCity, disabled: loading},
        ],
        [
            {type: 'checkbox', name: 'defaultShippingAddress', label: t("default_shipping"), checked: defaultShippingAddress, onChange: handleDefaultShippingAddress, disabled: loading},
        ],
        [
            {type: 'checkbox', name: 'defaultBillingAddress', label: t("default_invoice"), checked: defaultBillingAddress, onChange: handleDefaultBillingAddress, disabled: loading},
        ]
    ];

    const updateHandler = () => {
        setLoading(true);

        customerAddressAction.current?.update(
            id,
            streetLine1,
            countryCode,
            streetLine2,
            postalCode,
            city,
            defaultShippingAddress,
            defaultBillingAddress
        ).then((result) => {
            setLoading(false);

            customerAddressAction.current?.resetStore();
        });
    };

    const deleteHandler = () => {
        setLoading(true);

        customerAddressAction.current?.delete(id).then((result) => {
            setLoading(false);

            customerAddressAction.current?.resetStore();
        });
    };

    return (
        <>
            <CustomerAddressAction ref={customerAddressAction} />

            <form>
                <fieldset className="fieldset">
                    <legend>{t("update_title")}</legend> 
                    {renderForm(addressForm)}
                    {error && <p className="formError">{error}</p>}

                    <Button 
                        fullWidth={true}
                        loading={loading}
                        onClick={updateHandler}>{loading ? t("updating_address") : t("update_address")}</Button>

                    <Button 
                        fullWidth={true}
                        loading={loading}
                        onClick={deleteHandler}>{loading ? t("deleteing_address") : t("delete_address")}</Button>
                </fieldset>
            </form>
        </>
    );
}
