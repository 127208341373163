import { useMutation } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { VerifyCustomerAccountQuery } from "./VerifyCustomerAccountQuery";
import { VerifyCustomerAccountResult } from "../../gql/graphql";

type Variables = {
    token: string;
    password?: string;
}

type Props = {}

export interface VerifyRef {
    verify: (token: string, password?: string) => Promise<VerifyCustomerAccountResult>;
    resetStore: () => void;
}

function VerifyAction (
    props: Props,
    ref: Ref<VerifyRef>
) {
    const [verifyCustomer, { client }] = useMutation<{verifyCustomerAccount: VerifyCustomerAccountResult}, Variables>(VerifyCustomerAccountQuery);

    const verify = (token: string, password?: string): Promise<VerifyCustomerAccountResult> => (
        new Promise<VerifyCustomerAccountResult>((resolve, reject) => {
            verifyCustomer({
                variables: {
                    token,
                    password
                },
                onCompleted: ({ verifyCustomerAccount }) => {
                    resolve(verifyCustomerAccount);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    );

    const resetStore = () => {
        client.resetStore();
    }

    useImperativeHandle(ref, () => ({ verify, resetStore }));

    return <></>;
}

export default React.forwardRef(VerifyAction);
