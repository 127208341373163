import { gql } from "@apollo/client";

export const OrderQuery = gql`
query Order($id: ID!) {
    order(id: $id) {
        __typename
        id
        active
        code
        state
        orderPlacedAt
        shippingAddress {
            fullName
            streetLine1
            streetLine2
            postalCode
            city
        }
    }
}
`;
