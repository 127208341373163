import { ReactNode } from "react";
import { useTimeout } from "../../hooks";
import { CiCircleRemove } from "react-icons/ci";
import { ToastType } from "../../types/ToastType";
import classes from "./ToastComponent.module.scss"

type Props = {
    key: string,
    close: () => void,
    children: ReactNode,
    timeout: number,
    type?: ToastType
};

const getTypeClass = (type?: ToastType): string => {
    switch (type) {
        case ToastType.Warning: {
            return classes.warning;
        }
        case ToastType.Error: {
            return classes.error;
        }
        case ToastType.Standard:
        default: {
            return classes.normal;
        }
    }
}

export default function ToastComponent({ close, timeout, children, type }: Props) {
    useTimeout(close, timeout * 1000);

    return (
        <div className={[getTypeClass(type), classes.toast].join(" ")}>
            <div className={classes.text}>{children}</div>
            <CiCircleRemove size="1.4em" className={classes.closeBtn} onClick={close} />
        </div>
    );
}
