import { gql } from "@apollo/client";

export const AddPaymentToOrderQuery = gql`
mutation AddPaymentToOrder($input: PaymentInput!) {
    addPaymentToOrder(input: $input) {
        ... on Order {
            __typename
            id
            code
            payments {
                id
                method
                amount
                state
                transactionId
                errorMessage
                metadata
            }
        }
        ... on OrderPaymentStateError {
            __typename
            message
        }
        ... on IneligiblePaymentMethodError {
            __typename
            message
        }
        ... on PaymentFailedError {
            __typename
            message
        }
        ... on PaymentDeclinedError {
            __typename
            message
        }
        ... on OrderStateTransitionError {
            __typename
            message
            transitionError
            fromState
            toState
        }
        ... on NoActiveOrderError {
            __typename
            message
        }
    }
}
`;
