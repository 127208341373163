import { gql } from "@apollo/client";

export const ResetPasswordQuery = gql`
    mutation ResetPassword($token: String!, $password: String!) {
        resetPassword(token: $token, password: $password) {
            ... on CurrentUser {
                __typename
                id
                identifier
            }
            ... on PasswordResetTokenInvalidError {
                __typename
                message
            }
            ... on PasswordResetTokenExpiredError {
                __typename
                message
            }
            ... on PasswordValidationError {
                __typename
                message
            }
            ... on NativeAuthStrategyError {
                __typename
                message
            }
            ... on NotVerifiedError {
                __typename
                message
            }
        }
    }
`;
