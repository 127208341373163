import { Address, OrderAddress, ProductOption } from "../gql/graphql";

export const SIZE_OPTION_CODE = "size";
export const LENGTH_OPTION_CODE = "length";
export const FABRIC_OPTION_CODE = "fabric";

export const toRows = (array: Array<any>, count: number): Array<Array<any>> => (
    array.reduce((r, v, i) => ((r[i % count] = r[i % count] || []).push(v)), [])
);

export const chunk = (array: Array<any>, size: number): Array<Array<any>> => (
    Array.from({ length: Math.ceil(array.length / size) }, (v, i) => array.slice(i * size, i * size + size))
);

export const priceWithDecimals = (price: number | undefined, unit: string): string => {
    if (price) {
        return `${(price / 100).toFixed(2)} ${unit}`
    }

    return `${(0).toFixed(2)} ${unit}`
}

export const log = (...items: any) => {
    console.log(...items)
}

export const customerOrderPage = (orderCode: string) => (
    `/customer/order/${orderCode}`
)

const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const validateEmail = (email: string) => {
    return email.match(regexp);
};

export const getSize = (options: Array<ProductOption>): string => {
    const size = options.find((option) => option.group.name === SIZE_OPTION_CODE);
    if (size) {
        return size.name;
    }
    return "-";
}

export const getLength = (options: Array<ProductOption>): string => {
    const length = options.find((option) => option.group.name === LENGTH_OPTION_CODE);
    if (length) {
        return length.name;
    }
    return "-";
}

export const getFabric = (options: Array<ProductOption>): string => {
    const fabric = options.find((option) => option.group.name === FABRIC_OPTION_CODE);
    if (fabric) {
        return fabric.name;
    }
    return "-";
}

export const getFabricOption = (options: ProductOption[]) => (
    options.find((option: ProductOption) => (
        option.group.code === FABRIC_OPTION_CODE
    ))
);

export const getLengthOption = (options: ProductOption[]) => (
    options.find((option: ProductOption) => (
        option.group.code === LENGTH_OPTION_CODE
    ))
);

export const getSizeOption = (options: ProductOption[]) => (
    options.find((option: ProductOption) => (
        option.group.code === SIZE_OPTION_CODE
    ))
);

export const printAddress = ({ fullName, streetLine1, streetLine2, postalCode, city }: OrderAddress | Address) => (
    <address style={{marginBottom: "1em"}} itemScope itemType="https://schema.org/PostalAddress">
        {fullName && <>{fullName}<br /></>}
        {streetLine1 && <>{streetLine1}<br /></>}
        {streetLine2 && <>{streetLine2}<br /></>}
        {(postalCode && city) && <>{postalCode} {city}<br /></>}
    </address>
);
