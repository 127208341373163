import { ApolloQueryResult, FetchResult, useMutation } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { RegisterCustomerAccountQuery } from ".";
import { LoginQuery } from "./LoginQuery";
import { LogoutQuery } from "./LogoutQuery";
import { RequestPasswordResetQuery } from "./RequestPasswordResetQuery";
import { RefreshCustomerVerificationQuery } from "./RefreshCustomerVerification";
import { ResetPasswordQuery } from "./ResetPasswordQuery";
import { NativeAuthenticationResult, RefreshCustomerVerificationResult, RegisterCustomerAccountResult, RequestPasswordResetResult, ResetPasswordResult, Success } from "../../gql/graphql";

type RegisterVariables = {
    input: {
        emailAddress: string;
        firstName: string;
        lastName: string;
    }
};

type LoginVariables = {
    username: string;
    password: string;
    rememberMe?: Boolean;
};

type LogoutVariables = {};

type RequestPasswordResetVariables = {
    emailAddress: string;
};

type RefreshCustomerVerificationVariables = {
    emailAddress: string;
};

type ResetPasswordVariables = {
    token: string;
    password: string;
};

type Props = {
    onRegister?: (result: RegisterCustomerAccountResult) => void;
    onLogin?: (result: NativeAuthenticationResult) => void;
    onLogout?: (result: Success) => void;
    onRequestPasswordReset?: (result: RequestPasswordResetResult) => void;
    onRefreshCustomerVerification?: (result: RefreshCustomerVerificationResult) => void;
    onResetPassword?: (result: ResetPasswordResult) => void;
}

export interface AccountRef {
    register: (firstName: string, lastName: string, emailAddress: string) => Promise<RegisterCustomerAccountResult>;
    login: (username: string, password: string, rememberMe?: Boolean) => Promise<NativeAuthenticationResult>;
    logout: () => Promise<Success>;
    requestPasswordReset: (emailAddress: string) => Promise<RequestPasswordResetResult>;
    refreshCustomerVerification: (emailAddress: string) => Promise<FetchResult<{refreshCustomerVerification: RefreshCustomerVerificationResult}>>;
    resetPassword: (token: string, password: string) => Promise<FetchResult<{resetPassword: ResetPasswordResult}>>;
    resetStore: () => Promise<ApolloQueryResult<any>[] | null>;
}

function AccountAction (
    { onRegister, onLogin, onLogout, onRequestPasswordReset, onRefreshCustomerVerification, onResetPassword }: Props,
    ref: Ref<AccountRef>
) {
    const [registerMutation, { client }] = useMutation<{registerCustomerAccount: RegisterCustomerAccountResult}, RegisterVariables>(RegisterCustomerAccountQuery);

    const [loginMutation] = useMutation<{login: NativeAuthenticationResult}, LoginVariables>(LoginQuery);

    const [logoutMutation] = useMutation<{logout: Success}, LogoutVariables>(LogoutQuery);

    const [requestPasswordResetMutation] = useMutation<{requestPasswordReset: RequestPasswordResetResult}, RequestPasswordResetVariables>(RequestPasswordResetQuery);

    const [refreshCustomerVerificationMutation] = useMutation<{refreshCustomerVerification: RefreshCustomerVerificationResult}, RefreshCustomerVerificationVariables>(RefreshCustomerVerificationQuery);

    const [resetPasswordMutation] = useMutation<{resetPassword: ResetPasswordResult}, ResetPasswordVariables>(ResetPasswordQuery);

    const register = (firstName: string, lastName: string, emailAddress: string): Promise<RegisterCustomerAccountResult> => (
        new Promise<RegisterCustomerAccountResult>((resolve, reject) => {
            registerMutation({
                variables: {
                    input: {
                        firstName,
                        lastName,
                        emailAddress
                    }
                },
                onCompleted: ({ registerCustomerAccount }) => {
                    resolve(registerCustomerAccount);
                    onRegister?.(registerCustomerAccount);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    );

    const login = (username: string, password: string, rememberMe?: Boolean): Promise<NativeAuthenticationResult> => (
        new Promise<NativeAuthenticationResult>((resolve, reject) => {
            loginMutation({
                variables: { 
                    username,
                    password,
                    rememberMe
                },
                onCompleted: ({ login }) => {
                    resolve(login);
                    onLogin?.(login);
                },
                onError: (error) => {
                    reject(error);
                }
            });
        })
    )

    const logout = (): Promise<Success> => (
        new Promise<Success>((resolve, reject) => {
            logoutMutation({
                onCompleted: ({ logout }) => {
                    resolve(logout);
                    onLogout?.(logout);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    );

    const requestPasswordReset = (emailAddress: string): Promise<RequestPasswordResetResult> => (
        new Promise<RequestPasswordResetResult>((resolve, reject) => {
            requestPasswordResetMutation({
                variables: {
                    emailAddress
                },
                onCompleted: ({ requestPasswordReset }) => {
                    resolve(requestPasswordReset);
                    onRequestPasswordReset?.(requestPasswordReset);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    );

    const refreshCustomerVerification = (emailAddress: string): Promise<FetchResult<{refreshCustomerVerification: RefreshCustomerVerificationResult}>> => (
        refreshCustomerVerificationMutation({
            variables: {
                emailAddress
            },
            onCompleted: ({ refreshCustomerVerification }) => {
                onRefreshCustomerVerification?.(refreshCustomerVerification);
            }
        })
    );

    const resetPassword = (token: string, password: string): Promise<FetchResult<{resetPassword: ResetPasswordResult}>> => (
        resetPasswordMutation({
            variables: {
                token,
                password
            },
            onCompleted: ({ resetPassword }) => {
                onResetPassword?.(resetPassword);
            }
        })
    );

    const resetStore = () => (
        client.resetStore()
    );

    useImperativeHandle(ref, () => ({ register, login, logout, requestPasswordReset, refreshCustomerVerification, resetPassword, resetStore }));

    return <></>;
}

export default React.forwardRef(AccountAction);
