import { FetchResult, useLazyQuery, useMutation } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { OrderQuery, OrderByCodeQuery } from ".";
import { SetOrderShippingAddressQuery } from "./SetOrderShippingAddressQuery";
import { ActiveOrderResult, Order, SetCustomerForOrderResult } from "../../gql/graphql";
import { SetCustomerForOrderQuery } from "./SetCustomerForOrder";
import { SetOrderBillingAddressQuery } from "./SetOrderBillingAddressQuery";

export interface UntypedObject {
    [key: string]: any
}

type SetOrderAddressVars = {
    fullName?: string,
    company?: string,
    streetLine1: string,
    streetLine2?: string,
    city?: string,
    province?: string,
    postalCode?: string,
    countryCode: string,
    phoneNumber?: string,
    defaultShippingAddress?: Boolean
    defaultBillingAddress?: Boolean
    customFields?: UntypedObject
}

type SetCustomerVars = {
    title?: string,
    firstName: string,
    lastName: string,
    phoneNumber?: string,
    emailAddress: string,
    customFields?: UntypedObject
}

type Props = {
    onOrder?: (order: Order) => void;
    onAddressUpdated?: (result: ActiveOrderResult) => void;
    onCustomerUpdated?: (result: SetCustomerForOrderResult) => void;
}

export interface OrderRef {
    byId: (id: number) => Promise<FetchResult<{ order: Order }>>,
    byCode: (code: string) => Promise<FetchResult<{ orderByCode: Order }>>,
    setShipping: (data: SetOrderAddressVars) => Promise<ActiveOrderResult>,
    setBilling: (data: SetOrderAddressVars) => Promise<ActiveOrderResult>,
    setCustomer: (data: SetCustomerVars) => Promise<SetCustomerForOrderResult>,
    resetStore: () => void
}

function OrderAction (
    { onOrder, onAddressUpdated, onCustomerUpdated }: Props,
    ref: Ref<OrderRef>
) {
    const [byIdQuery] = useLazyQuery<{ order: Order }, { id: number; }>(OrderQuery, {
        onCompleted: ({ order }) => {
            onOrder?.(order);
        }
    });

    const [byCodeQuery, { client }] = useLazyQuery<{ orderByCode: Order }, { code: string; }>(OrderByCodeQuery, {
        onCompleted: ({ orderByCode }) => {
            onOrder?.(orderByCode);
        }
    });

    const [setShippingMutation] = useMutation<{ setOrderShippingAddress: ActiveOrderResult }, {input: SetOrderAddressVars}>(SetOrderShippingAddressQuery, {
        onCompleted: ({ setOrderShippingAddress }) => {
            onAddressUpdated?.(setOrderShippingAddress);
        }
    });

    const [setBillingMutation] = useMutation<{ setOrderBillingAddress: ActiveOrderResult }, {input: SetOrderAddressVars}>(SetOrderBillingAddressQuery, {
        onCompleted: ({ setOrderBillingAddress }) => {
            onAddressUpdated?.(setOrderBillingAddress);
        }
    });

    const [setCustomerMutation] = useMutation<{ setCustomerForOrder: SetCustomerForOrderResult }, {input: SetCustomerVars}>(SetCustomerForOrderQuery, {
        onCompleted: ({ setCustomerForOrder }) => {
            onCustomerUpdated?.(setCustomerForOrder);
        }
    });

    const byId = (id: number): Promise<FetchResult<{ order: Order }>> => (
        byIdQuery({
            variables: {
                id
            }
        })
    );

    const byCode = (code: string): Promise<FetchResult<{ orderByCode: Order }>> => (
        byCodeQuery({
            variables: {
                code
            }
        })
    );

    const setCustomer = (input: SetCustomerVars): Promise<SetCustomerForOrderResult> => (
        new Promise<SetCustomerForOrderResult>((resolve, reject) => {
            setCustomerMutation({
                variables: {
                    input
                },
                onCompleted: (result) => {
                    resolve(result.setCustomerForOrder);
                },
                onError: (error) => {
                    reject(error);
                }
            });
        })
    );

    const setShipping = (input: SetOrderAddressVars): Promise<ActiveOrderResult> => {
        return new Promise<ActiveOrderResult>((resolve, reject) => {
            setShippingMutation({
                variables: {
                    input
                },
                onCompleted: (result) => {
                    resolve(result.setOrderShippingAddress);
                },
                onError: (error) => {
                    reject(error);
                }
            });
        });
    }

    const setBilling = (input: SetOrderAddressVars): Promise<ActiveOrderResult> => {
        return new Promise<ActiveOrderResult>((resolve, reject) => {
            setBillingMutation({
                variables: {
                    input
                },
                onCompleted: (result) => {
                    resolve(result.setOrderBillingAddress);
                },
                onError: (error) => {
                    reject(error);
                }
            });
        });
    }

    const resetStore = () => {
        if (!client) {
            return;
        }

        client.resetStore();
    }

    useImperativeHandle(ref, () => ({ byId, byCode, setShipping, setBilling, setCustomer, resetStore }));

    return <></>;
}

export default React.forwardRef(OrderAction);
