import { useLazyQuery } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { PublicDataQuery } from "./PublicDataQuery";
import { PublicData } from "../../gql/graphql";

type Props = {}

export interface PublicDataRef {
    get: () => Promise<PublicData>,
    resetStore: () => void
}

function PublicDataAction (
    props: Props,
    ref: Ref<PublicDataRef>
) {
    const [query, { client }] = useLazyQuery<{getPublicData: PublicData}>(PublicDataQuery);

    const get = (): Promise<PublicData> => (
        new Promise<PublicData>((resolve, reject) => {
            query({
                onCompleted: ({ getPublicData }) => {
                    resolve(getPublicData);
                },
                onError: (error) => {
                    reject(error);
                }
            });
        })
    );

    const resetStore = () => {
        if (!client) {
            return;
        }

        client.resetStore();
    }

    useImperativeHandle(ref, () => ({ get, resetStore }));

    return <></>;
}

export default React.forwardRef(PublicDataAction);
