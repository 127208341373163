import { gql } from "@apollo/client";

export const CreateCustomerAddressQuery = gql`
mutation CreateCustomerAddress($input: CreateAddressInput!) {
    createCustomerAddress(input: $input) {
        __typename
        streetLine1
        streetLine2
        postalCode
        city
        country {
            code
        }
        defaultBillingAddress
        defaultShippingAddress
    }
}
`;
