import { Link } from "react-router-dom";
import classes from "./Terms.module.scss";

export default function Terms() {
    return (
        <>
            <div className="row">
                <div className="sixteen columns">
                    <p><i>All GDPR/legal pages on my website are written in Swedish only.</i></p>

                    <h2>Villkor hos Veras Ateljé</h2>
                    <p>Företaget Veras Ateljé har sitt säte på Boråsvägen 237, i Rydal. Vill du komma i kontakt med mig så kan du skicka ett mail till <a className={classes.link} href="mailto:butiken@verasatelje.se">butiken@verasatelje.se</a> så återkommer jag så fort jag hinner. Du kan även ringa mig på telefon <a className={classes.link} href="tel:+46 70 310 11 46">+46 70 310 11 46</a>.</p>
                    <h2>Villkor för köp i webbbutik</h2>
                    <p>För att genomföra ett köp måste jag spara vissa av dina personuppgifter. Du behöver inte skapa ett konto för att handla; men har du ett konto kan du enkelt när som helst se alla dina föregående och nuvarandre ordrar. Du kan läsa vidare om hur jag använder din data i min <Link className={classes.link} to="/privacypolicy">integritetspolicy</Link>.</p>
                    <p>När du hittat en, eller flera, produkter du gillar så kan du välja dess storlek samt det tyg du önskar få dem uppsydda i. Därefter kan du, från din kundkorg, välja att gå vidare till kassan och genomföra order. Du har även möjlighet att välja redan färdigsydda plagg om där finns några i mitt lager.</p>
                    <h2>Villkor för leverans</h2>
                    <p>Skräddarsydda beställningar är personligt skapade utifrån dina önskemål, därav föregås leveransen av en produktionstid. Produktionstiden kan variera beroende på hur många ordrar som ligger i systemet för tillfället. Men jag garanterar alltid leverans inom fyra veckor.</p>
                    <h2>Ångerrätt</h2>
                    <p>Jag följer de lagar och rekommendationer som finns presenterade hos konsumentverket. Enkelt sagt så har du ångerrätt på alla plagg som tas från mitt lager; skräddarsydda plagg som är skapade utifrån dina önskemål har jag dock inte möjlighet att erbjuda ångerrätt på. Det står tydligt på alla produkter om där finns en ångerrätt eller ej.</p>
                    <h2>Returer</h2>
                    <p>Om det är ett plagg som kommer från mitt lager har du en rätt att ångra ditt köp inom 14 dagar från det att du mottog din order. Det är viktigt att du kontaktar mig innan du returnerar din order för att jag ska kunna bekräfta att ångerrätt faktiskt är möjligt för din order.</p>
                    <p>En vara som returneras måste vara i samma skick som den var när jag skicka de den; använd orginalförpackningen när du returnerar plagg. Returer skall skickas till min atelje som har adress</p>
                    <ul className={classes.address}>
                        <li>Veras Ateljé</li>
                        <li>Boråsvägen 237</li>
                        <li>511 70 Rydal</li>
                    </ul>
                    <p>Det är du som kund som bekostar returfrakten, använd orginalförpackning när du skickar.</p>
                    <h2>Reklamationer</h2>
                    <p>Jag granskar noga alla ordrar jag skickar iväg, skulle där finnas en defekt eller en synlig skada på din vara när du mottar den kontaktar du mig på <a className={classes.link} href="mailto:butiken@verasatelje.se">butiken@verasatelje.se</a> eller på telefon, <a className={classes.link} href="tel:+46 70 310 11 46">+46 70 310 11 46</a> inom 14 dagar från att du har mottagit ordern.</p>
                    <p>För att jag skall kunna behandla din reklamation är det viktigt att du dokumenterar de skador och defekter du funnit innan du kontaktar mig. Notera att återgivning av ett tygs färg på skärm kan skilja sig från den faktiska färgen plagget har.</p>
                    <p>Jag gör alltid mitt bästa att presentera mina plagg och de tyg jag använder för att det ska framgå hur det ser ut i verkligheten. En färg som inte motsvarar exakt det önskemål du hade är inte en godtagbar anledning till reklamation.</p>
                </div>
            </div>
        </>
    );
}
