import { gql } from "@apollo/client";

export const ActiveCustomerQuery = gql`
query ActiveCustomer {
    activeCustomer {
        __typename
        firstName
        lastName
        emailAddress
        addresses {
            id
            streetLine1
            streetLine2
            postalCode
            city
            country {
                code
                name
            }
            defaultBillingAddress
            defaultShippingAddress
        }
        orders {
            items {
                id
                code
                state
            }
            totalItems
        }
    }
}
`;
