import { FetchResult, useMutation } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { RemoveOrderLineQuery } from ".";
import { AdjustOrderLineQuery } from "./AdjustOrderLineQuery";
import { RemoveOrderItemsResult, UpdateOrderItemsResult } from "../../gql/graphql";

type RemoveVariables = {
    orderLineId: string;
}

type AdjustVariables = {
    orderLineId: string;
    quantity: number;
}

type Props = {
    onRemoved?: (result: RemoveOrderItemsResult) => void;
    onAdjusted?: (result: UpdateOrderItemsResult) => void;
}

export interface OrderLineRef {
    removeOrderLine: (orderLineId: string) => Promise<FetchResult<{removeOrderLine: RemoveOrderItemsResult}>>;
    adjustOrderLine: (orderLineId: string, quantity: number) => Promise<FetchResult<{adjustOrderLine: UpdateOrderItemsResult}>>;
    resetStore: () => void;
}

function OrderLineAction (
    { onRemoved, onAdjusted }: Props,
    ref: Ref<OrderLineRef>
) {
    const [removeMutation, { client }] = useMutation<{removeOrderLine: RemoveOrderItemsResult}, RemoveVariables>(RemoveOrderLineQuery);

    const [adjustMutation] = useMutation<{adjustOrderLine: UpdateOrderItemsResult}, AdjustVariables>(AdjustOrderLineQuery);

    const removeOrderLine = (orderLineId: string): Promise<FetchResult<{removeOrderLine: RemoveOrderItemsResult}>> => (
        removeMutation({
            variables: {
                orderLineId
            },
            onCompleted: ({ removeOrderLine }) => {
                onRemoved?.(removeOrderLine);
            }
        })
    )

    const adjustOrderLine = (orderLineId: string, quantity: number): Promise<FetchResult<{adjustOrderLine: UpdateOrderItemsResult}>> => (
        adjustMutation({
            variables: {
                orderLineId,
                quantity
            },
            onCompleted: ({ adjustOrderLine }) => {
                onAdjusted?.(adjustOrderLine);
            }
        })
    )

    const resetStore = () => {
        client.resetStore();
    }

    useImperativeHandle(ref, () => ({ removeOrderLine, adjustOrderLine, resetStore }));

    return <></>;
}

export default React.forwardRef(OrderLineAction);
