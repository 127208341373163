import { Elements } from "@stripe/react-stripe-js";
import { StripeForm } from "../../StripeForm";
import getStripe from "../../stripeHelper";
import classes from "./PaymentMethodSelector.module.scss";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { PaymentMethodQuote } from "../../../gql/graphql";

type Props = {
    paymentMethod: PaymentMethodQuote;
    orderCode?: string;
    selected: boolean;
    onClick: () => void;
    clientSecret: string | undefined;
}

export default function StripeItem({ orderCode, paymentMethod, selected, onClick, clientSecret }: Props) {

    let containerClasses = [classes.itemContainer];

    if (selected) {
        containerClasses = [classes.selected, ...containerClasses];
    }

    const options = {
        clientSecret, // StripePaymentIntent ???
    };
    
    return (
        <div className={containerClasses.join(" ")} onClick={() => {
            onClick();
        }}>
            <div className={classes.titleContainer}>
                <p className={classes.title}>{paymentMethod.name}</p>
                <FaCcMastercard />
                <FaCcVisa />
            </div>

            {clientSecret && <Elements stripe={getStripe()} options={options}>
                <StripeForm show={selected} {...{orderCode}} />
            </Elements>}
        </div>
    );
}
