import { gql } from "@apollo/client";

export const VerifyCustomerAccountQuery = gql`
mutation VerifyCustomerAccount($token: String!, $password: String) {
    verifyCustomerAccount(token: $token, password: $password) {
        ... on CurrentUser {
            __typename
            id
            identifier
        }
        ... on VerificationTokenInvalidError {
            __typename
            message
        }
        ... on VerificationTokenExpiredError {
            __typename
            message
        }
        ... on MissingPasswordError {
            __typename
            message
        }
        ... on PasswordAlreadySetError {
            __typename
            message
        }
        ... on NativeAuthStrategyError {
            __typename
            message
        }
    }
}
`;