import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { ActiveCustomerContext } from "../contexts";
import { ActiveCustomerAction, ActiveCustomerRef } from "../actions/ActiveCustomerAction";
import { Customer } from "../gql/graphql";

type Props = {
    children: ReactNode
}

export function ActiveCustomerProvider({ children }: Props) {

    const activeCustomerAction = useRef<ActiveCustomerRef>(null);

    const [customer, setCustomer] = useState<Customer | undefined>(undefined);

    const contextValue = useMemo(() => ({ customer }), [customer]);

    useEffect(() => {
        activeCustomerAction.current?.get();
    }, [activeCustomerAction]);

    return (
        <>
            <ActiveCustomerContext.Provider value={contextValue}>
                <ActiveCustomerAction ref={activeCustomerAction} onCompleted={(customer) => {
                    // This check is because customer can return "null", which will cause
                    // issues in validity check further up the tree. We only want
                    // "undefined" as a "guest" customer.
                    if (customer) {
                        setCustomer(customer);
                    } else {
                        setCustomer(undefined);
                    }
                }}/>
                {children}
            </ActiveCustomerContext.Provider>
        </>
    );
}
