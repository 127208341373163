import classes from "./Fabric.module.scss";
import beigeWool from "./colors_0000_beige-wool.jpg";
import blueWhite from "./colors_0001_blue-white.jpg";
import green from "./colors_0002_green.jpg";
import red from "./colors_0003_red.jpg";
import { ProductOptionProps } from "../ProductOptionSelector";
import { useState } from "react";
import { Popover } from "react-tiny-popover";
import { useMediaQuery } from "@uidotdev/usehooks";

// TODO: To be replaced with "Fabrics" plugin.
const imageMap = new Map<string, string>([
    ["beige-wool", beigeWool],
    ["blue-white", blueWhite],
    ["green", green],
    ["red", red],
]);

export default function Fabric({ code, name, active, onSelect }: ProductOptionProps) {

    const [hidden, setHidden] = useState(true);

    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    
    const classNames = (active ? [classes.active, classes.container] : [classes.container]).join(" ");

    const handleClick = () => {
        onSelect?.(code);
    };

    const handleEnter = () => {
        if (!isMobile) {
            setHidden(false);
        }
    };

    const handleLeave = () => {
        if (!isMobile) {
            setHidden(true);
        }
    };

    const handleTouchStart = () => {
        if (isMobile) {
            setHidden(false);
        }
    };

    const handleTouchMove = () => {
        if (isMobile) {
            
        }
    };

    const handleTouchEnd = () => {
        if (isMobile) {
            setHidden(true);
        }
    };

    return (
        <>
            <Popover
                isOpen={!hidden}
                positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
                content={<div className={classes.fabricDetail} style={{ backgroundImage: `url(${imageMap.get(code)})` }} />}>
                <div className={classNames} onClick={handleClick} onMouseEnter={handleEnter} onMouseLeave={handleLeave} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onTouchCancel={handleTouchEnd}>
                    <div className={classes.image} style={{ backgroundImage: `url(${imageMap.get(code)})` }} />
                </div>
            </Popover>            
        </>
    );
}
