import { ApolloQueryResult, FetchResult, useLazyQuery } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { ActiveOrderQuery } from ".";
import { Order } from "../../gql/graphql";

type Props = {
    onCompleted: (order: Order) => void;
}

type Get = () => Promise<FetchResult<{activeOrder: Order}>>;

type DoReset = () => Promise<ApolloQueryResult<any>[] | null>;

export interface ActiveOrderRef {
    get: Get,
    resetStore: DoReset
}

function ActiveOrderAction (
    { onCompleted }: Props,
    ref: Ref<ActiveOrderRef>
) {
    const [query, { client }] = useLazyQuery<{activeOrder: Order}>(ActiveOrderQuery, {
        onCompleted: ({ activeOrder }) => {
            onCompleted(activeOrder);
        }
    });

    const get = (): Promise<FetchResult<{activeOrder: Order}>> => query()

    const resetStore = () => (
        client.resetStore()
    );

    useImperativeHandle(ref, () => ({ get, resetStore }));

    return <></>;
}

export default React.forwardRef(ActiveOrderAction);
