import React, { ComponentType, Ref, useImperativeHandle, useState } from "react";
import classes from "./ProductOptionSelector.module.scss";
import { ProductOption } from "../../gql/graphql";

export type ProductOptionProps = {
    id: string;
    code: string;
    name: string;
    active: boolean;

    onSelect?: (optionCode: string) => void
};

type Params = {
    options: Array<ProductOption> | undefined;
    onSelected: (variant: ProductOption | undefined) => void;
    POComponent: ComponentType<ProductOptionProps>;
}

export interface ProductOptionSelectorRef {
    reset: () => void;
}

function ProductOptionSelector(
    { options, onSelected, POComponent }: Params,
    ref: Ref<ProductOptionSelectorRef>
) {
    const [option, setOption] = useState<ProductOption | undefined>(undefined);
    
    const handleSelect = (code: string) => {
        const selected = options?.find((option: ProductOption) => (option.code === code));
        setOption(selected);

        if (selected) {
            onSelected(selected);
        }
    }

    const reset = () => {
        setOption(undefined);
        onSelected(undefined);
    };
    
    useImperativeHandle(ref, () => ({ reset }));
    
    return (
        <>
            <div className={classes.container}>
                {options?.sort((a, b) => (
                    (+a.id) - (+b.id)
                )).map(({ id, code, name }: ProductOption) => (
                    <POComponent 
                        key={`variant-${id}`}
                        id={id}
                        code={code}
                        name={name}
                        active={(option?.code === code)}
                        onSelect={handleSelect} />
                ))}
            </div>
        </>
    )
}

export default React.forwardRef(ProductOptionSelector);
