import { gql } from "@apollo/client";

export const LogoutQuery = gql`
    mutation Logout {
        logout {
            __typename
            success
        }
    }
`;
