import { useMutation } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { CreateCustomerAddressQuery, UpdateCustomerAddressQuery } from ".";
import { DeleteCustomerAddressQuery } from "./DeleteCustomerAddressQuery";
import { Address, Success } from "../../gql/graphql";

type AddressVars = {
    id?: string,
    streetLine1: string,
    streetLine2?: string,
    postalCode?: string,
    city?: string,
    countryCode: string,
    defaultShippingAddress?: Boolean,
    defaultBillingAddress?: Boolean
}

type DeleteVars = {
    id: string;
}

type Variables = {
    input: AddressVars
};

type Props = {}

export interface CustomerAddressRef {
    create: (
        streetLine1: string,
        countryCode: string,
        streetLine2?: string,
        postalCode?: string,
        city?: string,
        defaultShippingAddress?: Boolean,
        defaultBillingAddress?: Boolean
    ) => Promise<Address>,
    update: (
        id: string,
        streetLine1: string,
        countryCode: string,
        streetLine2?: string,
        postalCode?: string,
        city?: string,
        defaultShippingAddress?: Boolean,
        defaultBillingAddress?: Boolean
    ) => Promise<Address>,
    delete: (
        id: string
    ) => Promise<Success>,
    resetStore: () => void
}

function CustomerAddressAction (
    props: Props,
    ref: Ref<CustomerAddressRef>
) {
    const [createMutation, { client }] = useMutation<{createCustomerAddress: Address}, Variables>(CreateCustomerAddressQuery);

    const [updateMutation] = useMutation<{updateCustomerAddress: Address}, Variables>(UpdateCustomerAddressQuery);

    const [deleteMutation] = useMutation<{deleteCustomerAddress: Success}, DeleteVars>(DeleteCustomerAddressQuery);

    const create = (
        streetLine1: string,
        countryCode: string,
        streetLine2?: string,
        postalCode?: string,
        city?: string,
        defaultShippingAddress?: Boolean,
        defaultBillingAddress?: Boolean
    ): Promise<Address> => (
        new Promise<Address>((resolve, reject) => {
            createMutation({
                variables: {
                    input: {
                        streetLine1,
                        streetLine2,
                        postalCode,
                        city,
                        countryCode,
                        defaultShippingAddress,
                        defaultBillingAddress
                    }
                },
                onCompleted: ({ createCustomerAddress }) => {
                    resolve(createCustomerAddress);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    );

    const update = (
        id: string,
        streetLine1: string,
        countryCode: string,
        streetLine2?: string,
        postalCode?: string,
        city?: string,
        defaultShippingAddress?: Boolean,
        defaultBillingAddress?: Boolean
    ): Promise<Address> => (
        new Promise<Address>((resolve, reject) => {
            updateMutation({
                variables: {
                    input: {
                        id,
                        streetLine1,
                        streetLine2,
                        postalCode,
                        city,
                        countryCode,
                        defaultShippingAddress,
                        defaultBillingAddress
                    }
                },
                onCompleted: ({ updateCustomerAddress }) => {
                    resolve(updateCustomerAddress);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    );

    const deleteFn = (id: string): Promise<Success> => (
        new Promise<Success>((resolve, reject) => {
            deleteMutation({
                variables: {
                    id
                },
                onCompleted: ({ deleteCustomerAddress }) => {
                    resolve(deleteCustomerAddress);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    )

    const resetStore = () => {
        client.resetStore();
    }

    useImperativeHandle(ref, () => ({ create, update, delete: deleteFn, resetStore }));

    return <></>;
}

export default React.forwardRef(CustomerAddressAction);
