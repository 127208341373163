import { renderForm } from "@agoransson/formhelper";
import { useRef, useState } from "react";
import { AccountAction, AccountRef } from "../../actions/AccountAction";
import { useTranslation } from "react-i18next";
import { NativeAuthStrategyError, RefreshCustomerVerificationResult } from "../../gql/graphql";

const isError = (input: RefreshCustomerVerificationResult): input is NativeAuthStrategyError => (
    (input as NativeAuthStrategyError).errorCode !== undefined
);

export default function RequestTokenForm() {

    const { t } = useTranslation("login");

    const accountAction = useRef<AccountRef>(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [emailAddress, setEmailAddress] = useState('');

    const handleEmailAddress = (value: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(value.currentTarget.value);
    }

    const handleSubmit = (evt: React.FormEvent) => {
        evt.preventDefault();

        setLoading(true);

        accountAction.current?.refreshCustomerVerification(emailAddress)
    }

    const refreshTokenForm = [
        [
            {type: 'email', name: 'emailAddress', placeholder: t("email"), onChange: handleEmailAddress, disabled: loading, required: true},
        ],
        [
            {type: 'submit', title: loading ? 'Skickar begäran...' : 'Begär ny verifieringsnyckel', disabled: loading}
        ]
    ];

    return (
        <>
            <AccountAction ref={accountAction} onRefreshCustomerVerification={(result) => {
                if (isError(result)) {
                    setError(result.message)
                }
            }} />
            

            <form onSubmit={handleSubmit}>
                <fieldset className="fieldset">
                    {renderForm(refreshTokenForm)}
                    {error && <p className="formError">{error}</p>}
                </fieldset>
            </form>
        </>
    );
}