import { InMemoryCacheConfig, makeVar } from "@apollo/client";

export const languageVar = makeVar("sv");

export const CacheConfig: InMemoryCacheConfig = {
    typePolicies: {
        // Order.lines: ...
        Order: {
            fields: {
                lines: {
                    merge(existing = [], incoming: any[]) {
                        return [...incoming];
                    }
                }
            }
        },

        // OrderLine.items: only use the incoming data, as that should be the latest from server.
        OrderLine: {
            fields: {
                items: {
                    merge(existing = [], incoming: any[]) {
                        return [...incoming];
                    },
                },
            },
        },
        Query: {
            fields: {
                language: {
                    read() {
                        return languageVar;
                    },
                },
            },
        },
    },
};
