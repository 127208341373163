import { FetchResult, useMutation } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { TransitionOrderToStateQuery } from ".";
import { TransitionOrderToStateResult } from "../../gql/graphql";

type Variables = {
    state: string;
}

type Props = {
    onCompleted: (order: TransitionOrderToStateResult) => void;
}

type SetOrderState = (token: string, password?: string) => Promise<FetchResult<{transitionOrderToState: TransitionOrderToStateResult}>>;

export interface OrderStateRef {
    setOrderState: SetOrderState,
    resetStore: () => void;
}

function OrderStateAction (
    { onCompleted }: Props,
    ref: Ref<OrderStateRef>
) {
    const [mutate, { client }] = useMutation<{transitionOrderToState: TransitionOrderToStateResult}, Variables>(TransitionOrderToStateQuery);

    const setOrderState = (orderState: string): Promise<FetchResult<{transitionOrderToState: TransitionOrderToStateResult}>> => (
        mutate({
            variables: {
                state: orderState
            },
            onCompleted: ({ transitionOrderToState }) => {
                onCompleted(transitionOrderToState);
            }
        })
    )

    const resetStore = () => {
        client.resetStore();
    }

    useImperativeHandle(ref, () => ({ setOrderState, resetStore }));

    return <></>;
}

export default React.forwardRef(OrderStateAction);
