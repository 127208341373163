import React, { useRef, useState } from "react";
import { renderForm } from '@agoransson/formhelper';
import { AccountAction, AccountRef } from "../../actions/AccountAction";
import { useTranslation } from "react-i18next";
import { MissingPasswordError, NativeAuthStrategyError, PasswordValidationError } from "../../gql/graphql";
import { Button } from "../Button";

type Props = {
    onResult?: (success: boolean) => void;
}

export default function RegisterForm({ onResult }: Props) {

    const { t } = useTranslation("register");

    const accountAction = useRef<AccountRef>(null);

    const [loading, setLoading] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [acceptDataStorage, setAcceptDataStorage] = useState(false);

    const [error, setError] = useState<string | undefined>();

    const handleFirstName = (value: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(value.currentTarget.value);
    }

    const handleLastName = (value: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(value.currentTarget.value);
    }

    const handleEmailAddress = (value: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(value.currentTarget.value);
    }

    const handleAcceptDataStorage = (value: React.ChangeEvent<HTMLInputElement>) => {
        setAcceptDataStorage(value.currentTarget.checked);
    }

    const registerForm = [
        [
            {type: 'text', name: 'firstName', placeholder: t("firstname"), value: firstName, onChange: handleFirstName, disabled: loading},
            {type: 'text', name: 'lastName', placeholder: t("lastname"), value: lastName, onChange: handleLastName, disabled: loading},
        ],
        [
            {type: 'email', name: 'emailAddress', placeholder: t("email"), value: emailAddress, onChange: handleEmailAddress, disabled: loading},
        ],
        [
            {type: 'checkbox', name: 'acceptDataStorage', label: t("consent_text"), value: acceptDataStorage, onChange: handleAcceptDataStorage, disabled: loading},
        ]
    ];

    const registerHandler = () => {
        if (!acceptDataStorage) {
            setError(t("accept_gdpr_checkbox"));
            return;
        }

        setLoading(true);
        setError(undefined);

        setLoading(true);
        setError(undefined);

        accountAction.current?.register(
            firstName,
            lastName,
            emailAddress
        ).then((result) => {
            console.log(result);
            setLoading(false);
            let success = false;

            switch (result.__typename) {
                case "MissingPasswordError": {
                    setError((result as MissingPasswordError).message);
                    break;
                }
                case "NativeAuthStrategyError": {
                    setError((result as NativeAuthStrategyError).message);
                    break;
                }
                case "PasswordValidationError": {
                    setError((result as PasswordValidationError).message);
                    break;
                }
                case "Success": {
                    success = true;
                    accountAction.current?.resetStore();
                    break;
                }
            }

            onResult?.(success);
        });
    }

    return (
        <>
            <AccountAction ref={accountAction} />

            <form>
                <fieldset className="fieldset">
                    <legend>{t("title")}</legend> 
                    {renderForm(registerForm)}
                    {error && <p className="formError">{error}</p>}

                    <Button
                        fullWidth={true}
                        loading={loading}
                        disabled={acceptDataStorage === false}
                        onClick={registerHandler}>{loading ? t("creating") : t("create")}</Button>
                </fieldset>
            </form>
        </>
    );
}
