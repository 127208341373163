import map from './veras_map.png';
import classes from './Hitta.module.scss';
import { useTranslation } from 'react-i18next';

// const columnClasses = ["col", "classes.text"].join(" ");

const heading = "Ateljén är granne med Rydals Museum"

const texts = `
Besöksadress
Boråsvägen 237
511 70 Rydal

Ring gärna innan du kommer besök, använd SMS eller telefon.`

export default function Hitta() {

    const { t } = useTranslation("find");

    return (
        <>
            <div className="row">
                <div className="sixteen columns">
                    <h1 className="pagetitle">{t("title")}</h1>
                </div>
            </div>

            <div className="row">
                <div className="one-half column">
                    <img className={classes.image} src={map} alt={t("image_alt")} />
                </div>
                <div className="one-half column">
                    <h3>{heading}</h3>
                    {texts.split("\n").map((text: string, index: number) => (
                        <p key={`find-${index}`}>{text}</p>
                    ))}
                </div>
            </div>

        </>
    );
}
