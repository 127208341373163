import React, { useRef, useState } from "react";
import { renderForm } from '@agoransson/formhelper';
import { useTranslation } from "react-i18next";
import { AccountAction, AccountRef } from "../../actions/AccountAction";
import { InvalidCredentialsError, NativeAuthStrategyError, NotVerifiedError } from "../../gql/graphql";
import { Button } from "../Button";

type Props = {
    onResult?: (result: boolean) => void;
};

export default function LoginForm({ onResult }: Props) {

    const { t } = useTranslation("login");

    const accountAction = useRef<AccountRef>(null);

    const [loading, setLoading] = useState(false);

    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const [error, setError] = useState<string | undefined>();

    const handleEmailAddress = (value: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(value.currentTarget.value);
    }

    const handlePassword = (value: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(value.currentTarget.value);
    }

    const handleRememberMe = (value: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(value.currentTarget.checked);
    }

    const loginForm = [
        [
            {type: 'email', name: 'emailAddress', placeholder: t("email"), value: emailAddress, onChange: handleEmailAddress, disabled: loading, required: true},
        ],
        [
            {type: 'password', name: 'password', placeholder: t("password"), value: password, onChange: handlePassword, disabled: loading, required: true},
        ],
        [
            {type: 'checkbox', name: 'rememberMe', label: t("remember_this_computer"), value: rememberMe, onChange: handleRememberMe, disabled: loading},
        ]
    ];

    const loginHandler = () => {
        setLoading(true);
        setError(undefined);

        accountAction.current?.login(emailAddress, password, rememberMe).then((result) => {
            setLoading(false);

            let success = false;

            switch (result.__typename) {
                case "InvalidCredentialsError": {
                    setError((result as InvalidCredentialsError).message);
                    break;
                }
                case "NativeAuthStrategyError": {
                    setError((result as NativeAuthStrategyError).message);
                    break;
                }
                case "NotVerifiedError": {
                    setError((result as NotVerifiedError).message);
                    break;
                }
                case "CurrentUser": {
                    success = true;
                    accountAction.current?.resetStore();
                    break;
                }
                default: {
                    setError("Unknown error.");
                    break;
                }
            }

            onResult?.(success);
        });
    };

    return (
        <>
            <AccountAction ref={accountAction} />

            <form>
                <fieldset className="fieldset">
                    <legend>{t("title")}</legend> 
                    {renderForm(loginForm)}
                    {error && <p className="formError">{error}</p>}

                    <Button 
                        fullWidth={true}
                        loading={loading}
                        onClick={loginHandler}>{loading ? t("logging_in") : t("login")}</Button>
                </fieldset>
            </form>
        </>
    );
}
