import { useTranslation } from "react-i18next";
import { priceWithDecimals } from "../helpers";
import classes from './pricetag.module.scss';

type Props = {
    price: number | undefined;
}

export default function PriceTag(props: Props) {

    const { price } = props;

    const { t } = useTranslation("priceTag");

    return (
        <div className={classes.container}>
            <h2 className={classes.price}>{t("price")}: {price ? priceWithDecimals(price, t("unit")) : undefined}</h2>
        </div>
    );
}