import map from './veras_map.png';
import classes from './Location.module.scss';

const heading = "Ateljén är granne med Rydals Museum"

const texts = `Min ateljé ligger mitt i det historiska centrumet för textilindustrin i Sverige, i de gamla lokalerna som hör till Rydals Spinneri.

Ta dig in genom grindarna till vänster när du anländer till Rydals Museum, så ser du snart min skylt. Ring på klockan när du är framme så möter jag dig.

Det finns gott om parkeringsmöjligheter utanför min ateljé om du skulle välja bilen istället för bussen för ditt besök.

Ring gärna innan du kommer på besök då jag ibland är iväg och köper in material från lokala butiker i omnejden.`


export default function LocationPage() {

    return (
        <>
            <div className="row">
                <div className="sixteen columns">
                    <h1 className="pagetitle">Hitta till Veras Ateljé</h1>
                </div>
            </div>

            <div className="row">
                <div className="one-half column">
                    <img className={classes.image} src={map} alt="Karta till Veras Ateljé"/>
                </div>
                <div className="one-half column">
                    <h3>{heading}</h3>
                    {texts.split("\n").map((text: string, index: number) => (
                        <p key={`find-${index}`}>{text}</p>
                    ))}
                </div>
            </div>

        </>
    );
}
