import { ApolloQueryResult, FetchResult, useLazyQuery } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { GalleryQuery } from "./GalleryQuery";
import { Asset } from "../../gql/graphql";


type Props = {
    onCompleted: (gallery: Array<Asset>) => void;
};

type Get = () => Promise<FetchResult<{getGallery: Array<Asset>}>>;

type DoReset = () => Promise<ApolloQueryResult<any>[] | null>;

export interface GalleryRef {
    get: Get,
    resetStore: DoReset
}

function GalleryAction (
    { onCompleted }: Props,
    ref: Ref<GalleryRef>
) {
    const [query, { client }] = useLazyQuery<{getGallery: Array<Asset>}>(GalleryQuery, {
        onCompleted: ({ getGallery }) => {
            onCompleted(getGallery);
        }
    });

    const get = (): Promise<FetchResult<{getGallery: Array<Asset>}>> => query()

    const resetStore = () => (
        client.resetStore()
    );

    useImperativeHandle(ref, () => ({ get, resetStore }));

    return <></>;
}

export default React.forwardRef(GalleryAction);
