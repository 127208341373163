import { gql } from "@apollo/client";

export const SetOrderShippingMethodQuery = gql`
mutation SetOrderShippingMethod($shippingMethodId: [ID!]!) {
    setOrderShippingMethod(shippingMethodId: $shippingMethodId) {
        ... on Order {
            __typename
            id
            code
            shipping
            shippingWithTax
            subTotal
            subTotalWithTax
            total
            totalWithTax
            shippingLines {
                shippingMethod {
                    id
                    name
                    description
                }
                price
            }
        }
        ... on OrderModificationError {
            __typename
            message
        }
        ... on IneligibleShippingMethodError {
            __typename
            message
        }
        ... on NoActiveOrderError {
            __typename
            message
        }
    }
}
`;
