import { SearchResult } from '../../gql/graphql';
import ProductItem from './ProductItem';
import classes from './productsgrid.module.scss';

export default function ProductsGrid(props: { items: Array<SearchResult> }) {
    const { items } = props;
    return (
        <>
            <div className={classes.grid}>
                {items.map((item: SearchResult) => (
                    <ProductItem {...item} key={item?.slug} />
                ))}
                <li className={classes.lastChild} />
            </div>
        </>
    );
}

