import { Link } from 'react-router-dom';
import classes from './navigationlink.module.scss';

type NavigationLinkProps = {
    to: string;
    children: JSX.Element | string;
};

export default function NavigationLink(props: NavigationLinkProps) {
    const { to, children } = props;

    return (
        // eslint-disable-next-line
        <Link className={classes.navlink} to={to}>
            {children}
        </Link>
    );
}