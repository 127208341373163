import { gql } from "@apollo/client";

export const GalleryQuery = gql`
    query Gallery {
        getGallery {
            id
            preview
            source
            width
            height
        }
    }
`;
