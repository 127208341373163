import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountAction, AccountRef } from "../../actions/AccountAction";
import { PasswordResetForm } from "../../components/PasswordResetForm";
import { useSearchParams } from "react-router-dom";

export default function PasswordResetPage() {

    const { t } = useTranslation("password_reset");

    const [searchParams] = useSearchParams();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [token, setToken] = useState(searchParams.get("token"));

    const accountAction = useRef<AccountRef>(null);
    
    return (
        <>
            <AccountAction ref={accountAction} />

            <div className="column">
                <h1>{t("reset_password_title")}</h1>
                <p>{t("reset_password_description")}</p>
            </div>
            <div className="row">
                <div className="one-half column">
                    <PasswordResetForm defaultToken={token} />
                </div>
                <div className="one-half column">
                </div>
            </div>
        </>
    );
}
