import { useLazyQuery, useMutation } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { EligibleShippingMethodsQuery } from ".";
import { SetOrderShippingMethodQuery } from "./SetOrderShippingMethodQuery";
import { SetOrderShippingMethodResult, ShippingMethodQuote } from "../../gql/graphql";

type Props = {
    onShippingMethods?: (result: Array<ShippingMethodQuote>) => void;
}

export interface ShippingMethodRef {
    get: () => Promise<Array<ShippingMethodQuote>>,
    set: (shippingMethodId: Array<string>) => Promise<SetOrderShippingMethodResult>,
    resetStore: () => void;
}

function ShippingMethodAction (
    { onShippingMethods }: Props,
    ref: Ref<ShippingMethodRef>
) {    
    const [getMethods, { client: getClient }] = useLazyQuery<{eligibleShippingMethods: Array<ShippingMethodQuote>}>(EligibleShippingMethodsQuery, {
        onCompleted: ({ eligibleShippingMethods }) => {
            onShippingMethods?.(eligibleShippingMethods);
        }
    });

    const [setMethod, { client: setClient }] = useMutation<{setOrderShippingMethod: SetOrderShippingMethodResult}, {shippingMethodId: Array<string>}>(SetOrderShippingMethodQuery);

    const get = (): Promise<Array<ShippingMethodQuote>> => (
        new Promise<Array<ShippingMethodQuote>>((resolve, reject) => {
            getMethods({
                onCompleted: (result) => {
                    resolve(result.eligibleShippingMethods);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    );

    const set = (shippingMethodId: Array<string>): Promise<SetOrderShippingMethodResult> => (
        new Promise<SetOrderShippingMethodResult>((resolve, reject) => {
            setMethod({
                variables: {
                    shippingMethodId
                },
                onCompleted: (result) => {
                    resolve(result.setOrderShippingMethod);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    );

    const resetStore = () => {
        if (setClient) {
            setClient.resetStore();
        } else if (getClient) {
            getClient.resetStore();
        } 
    }

    useImperativeHandle(ref, () => ({ get, set, resetStore }));

    return <></>;
}

export default React.forwardRef(ShippingMethodAction);
