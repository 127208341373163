import { gql } from "@apollo/client";

export const ActiveOrderQuery = gql`
    query ActiveOrder {
        activeOrder {
            __typename
            id
            code
            shipping
            shippingWithTax
            subTotal
            subTotalWithTax
            total
            totalWithTax
            shippingAddress {
                fullName
                streetLine1
                streetLine2
                city
                postalCode
                country
                countryCode
                phoneNumber
                customFields
            }
            shippingLines {
                shippingMethod {
                    code
                }
                price
            }
            payments {
                id
                method
                state
                transactionId
                errorMessage
                metadata
            }
            lines {
                id
                featuredAsset {
                    preview
                }
                linePriceWithTax
                productVariant {
                    name
                    price
                    product {
                        name
                    }
                    priceWithTax
                    options {
                        name
                        code
                        group {
                            name
                            code
                        }
                    }
                }
                unitPrice
                unitPriceWithTax
                quantity
            }
        }
    }
`;