import { useKeenSlider } from "keen-slider/react";
import { useEffect, useState } from "react";
import classes from "./PreviewSlider.module.scss";
import 'keen-slider/keen-slider.min.css';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Photo } from "react-photo-album";
import { Asset } from "../../../gql/graphql";
import { useMediaQuery } from "@uidotdev/usehooks";

type Props = {
    assets: Array<Asset>;
    galleryId?: string;
};

const breakpoints = [1680, 1440, 1080];

export default function PreviewSlider({ assets, galleryId }: Props) {
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const [photos, setPhotos] = useState<Array<Photo>>([]);
    const [index, setIndex] = useState(-1);

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
        {
        },
        [
        ]
    );

    useEffect(() => {
        const photos: Array<Photo> = assets.map((asset) => ({
            src: `${asset.source}`,
            width: asset.width,
            height: asset.height,
            srcSet: breakpoints.map((breakpoint) => {
                const height = Math.round((asset.height / asset.width) * breakpoint);
                return {
                    src: `${asset.source}?w=${breakpoint}&h=${height}&mode=crop`,
                    width: breakpoint,
                    height
                };
            })
        }));

        setPhotos(photos);
    }, [assets]);

    return (
        <>
            <div ref={sliderRef} className="keen-slider" id={galleryId}>
                {assets.map(({ preview, name }, index) => (
                    <div className="keen-slider__slide" key={`asset-${index}`}>
                        <img onClick={() => {
                            setIndex(index);
                        }} className={classes.previewImage} src={`${preview}?preset=product`} alt={name} />
                    </div>
                ))}
            </div>

            <div className={classes.thumbcontainer}>
                {assets && assets.map(({ preview, name }, index) => (
                    <img className={classes.thumb} onClick={() => {
                        instanceRef.current?.moveToIdx(index);
                    }} src={`${preview}?preset=${isMobile ? "tiny" : "thumb"}`} key={`thumb-${index}`} alt={name} />
                ))}
            </div>

            <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                zoom={{
                    scrollToZoom: true
                }}
                close={() => setIndex(-1)}
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </>
    );
}
