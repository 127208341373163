import { Link } from 'react-router-dom';
import { PiUserCircleThin } from 'react-icons/pi';
import classes from "./AccountLink.module.scss";
import { useActiveCustomer } from '../../hooks';
import { useMediaQuery } from '@uidotdev/usehooks';

type AccountLinkProps = {
};

export default function AccountLink(props: AccountLinkProps) {
    const { customer } = useActiveCustomer();

    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const isLoggedin = customer !== null && customer !== undefined;

    return (
        <>
            {isLoggedin && <Link className={classes.link} to="/customer">
                <PiUserCircleThin size={isMobile ? "1.8em" : ""} />
            </Link>}
            {!isLoggedin && <Link className={classes.link} to="/login">
                <PiUserCircleThin size={isMobile ? "1.8em" : ""} />
            </Link>}
        </>
    );
}
