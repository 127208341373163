import React, { Ref } from "react";
import Length from "./Length";
import { ProductOption } from "../../../gql/graphql";
import ProductOptionSelector, { ProductOptionSelectorRef } from "../ProductOptionSelector";

type Params = {
    options: Array<ProductOption> | undefined;
    onSelected: (variant: ProductOption | undefined) => void;
}

function LengthSelector(
    { options, onSelected }: Params,
    ref: Ref<ProductOptionSelectorRef>
) {
    return (
        <>
            <ProductOptionSelector
                ref={ref}
                POComponent={Length}
                options={options}
                onSelected={onSelected} />
        </>
    )
}

export default React.forwardRef(LengthSelector);
