import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import {
    PiFacebookLogoThin,
    PiTwitterLogoThin,
    PiPinterestLogoThin,
    PiWhatsappLogoThin,
    PiLinkedinLogoThin,
    PiTelegramLogoThin
} from "react-icons/pi";
import classes from "./SocialSharingComponent.module.scss";
import { CiMail } from "react-icons/ci";
import { Product } from "../../gql/graphql";

type Props = {
    product?: Product
};

export default function SocialSharingComponent({ product }: Props) {
    const iconSize = "2em";
    const location = window.location.href;

    const featuredMediaUrl = product?.featuredAsset?.source;
    const emailBody = `I found this lovely item at Veras Ateljé.`;

    return (
        <>
            <div className={classes.container}>
                <TwitterShareButton url={location}>
                    <PiTwitterLogoThin size={iconSize} />
                </TwitterShareButton>

                <FacebookShareButton url={location} >
                    <PiFacebookLogoThin size={iconSize} />
                </FacebookShareButton>

                {featuredMediaUrl && <PinterestShareButton url={location}  media={featuredMediaUrl}>
                    <PiPinterestLogoThin size={iconSize} />
                </PinterestShareButton>}

                <WhatsappShareButton url={location} >
                    <PiWhatsappLogoThin size={iconSize} />
                </WhatsappShareButton>

                <LinkedinShareButton url={location} >
                    <PiLinkedinLogoThin size={iconSize} />
                </LinkedinShareButton>

                <TelegramShareButton url={location} >
                    <PiTelegramLogoThin size={iconSize} />
                </TelegramShareButton>

                <EmailShareButton url={location} subject={product?.name} body={emailBody} >
                    <CiMail size={iconSize} />
                </EmailShareButton>

            </div>
        </>
    );
}
