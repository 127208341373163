import jacket from "./jacket.jpeg";
// import grindarMotParkering from "./grindar_mot_parkering.jpg";
import weddingDress from "./wedding_dress.jpeg";
import sewingMachine from "./sewing_machine.jpeg";
import classes from "./About.module.scss";
import { heading as aboutHeading, texts as aboutTexts } from "../../components/about";
// import { heading as ateljeHeading, texts as ateljeTexts } from "../../components/atelje";
// import { heading as workHeading, texts as workTexts } from "../../components/work";
import { heading as productsHeading, texts as productsTexts } from "../../components/products";

export default function About() {

    return (
        <>
            <div className="row">
                <div className="sixteen columns">
                    <h1 className="pagetitle">Om Veras Ateljé</h1>
                </div>
            </div>

            <div className="row">
                <div className="one-third column">
                    <img className={classes.image} src={weddingDress} alt="Justering av bröllopsklänning."/>
                </div>
                <div className="two-thirds column">
                    <h3 className={classes.heading}>{aboutHeading}</h3>
                    {aboutTexts.split("\n").map((text: string, index: number) => (
                        <p key={`about-${index}`}>{text}</p>
                    ))}
                </div>
            </div>

            {/* <div className="row">
                <div className="one-half column">
                    <h3 className={classes.heading}>{ateljeHeading}</h3>
                    {ateljeTexts.split("\n").map((text: string, index: number, arr: string[]) => (
                        ((index + 1) === arr.length ? <p key={`atelje-${index}`}>{text}<a href="tel:+46703101146">+46 (0) 70 310 11 46</a></p> : <p key={`atelje-${index}`}>{text}</p>)
                    ))}
                </div>
                <div className="one-half column">
                    <img className={classes.image} src={grindarMotParkering} alt="Grinder in mot parkeringen utanför Rydals museum."/>
                </div>
            </div> */}

            <div className="row">
                <div className="one-third column">
                    <img className={classes.image} src={jacket} alt="Kavaj."/>
                </div>
                {/* <div className="one-third column">
                    <h3 className={classes.heading}>{workHeading}</h3>
                    {workTexts.split("\n").map((text: string, index: number) => (
                        <p key={`work-${index}`}>{text}</p>
                    ))}
                </div> */}
                <div className="one-third column">
                    <img className={classes.image} src={sewingMachine} alt="Symaskin."/>
                </div>
            </div>

            <div className="row">
                <div className="one-half column">
                    <h3 className={classes.heading}>{productsHeading}</h3>
                    {productsTexts.split("\n").map((text: string, index: number) => (
                        <p key={`products-${index}`}>{text}</p>
                    ))}
                </div>
                <div className="one-half column">
                    {/* <img className={classes.image} src={provdockor} alt="industrisymaskin och provdockor"/> */}
                </div>
            </div>

        </>
    );
}
