import { ReactNode, useMemo, useState } from "react";
import { DrawerContext } from "../contexts";
import { createPortal } from "react-dom";
import Hamburger from "hamburger-react";
import { Backdrop, CartDrawer, NavDrawer } from "../components/Drawers";

import classes from "./Drawer.module.scss";

type Props = {
    children: ReactNode
}

export function DrawerProvider({ children }: Props) {

    const [navOpen, setNavOpen] = useState(false);
    const [cartOpen, setCartOpen] = useState(false);

    const openNav = () => setNavOpen(true);
    const closeNav = () => setNavOpen(false);

    const openCart = () => setCartOpen(true);
    const closeCart = () => setCartOpen(false);
    
    const contextValue = useMemo(
        () => ({ navOpen, openNav, closeNav, cartOpen, openCart, closeCart }),
        [cartOpen, navOpen]
    );

    return (
        <>
            <DrawerContext.Provider value={contextValue}>
                {children}
                {
                    <div className={classes.hamburger}>
                        <Hamburger rounded size={22} toggle={setNavOpen} toggled={navOpen} />
                    </div>
                }
                {createPortal(
                    <div>
                        <Backdrop open={navOpen || cartOpen} />
                        <NavDrawer open={navOpen} />
                        <CartDrawer open={cartOpen} order={undefined} />
                    </div>,
                    document.body
                )}
            </DrawerContext.Provider>
        </>
    );
}
