/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;

const publishableKey = "pk_test_51KmKDGCeQgIWq8RTX9ySQNnJmprTerhulGAY2DQTFqBnaInKsGHcp8lZclW6Jnn9TMKJqO45iuosXHtfjrUnXKi500emVGGeJa";

// process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!

const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe(publishableKey);
    }
    return stripePromise;
};

export default getStripe;