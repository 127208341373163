import { useState } from "react";
import { RequestTokenForm } from "../../components/RequestTokenForm";
import { VerifyForm } from "../../components/VerifyForm";
import { Navigate, useSearchParams } from "react-router-dom";

export default function Verify() {

    const [success, setSuccess] = useState(false);

    const [searchParams] = useSearchParams();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [token, setToken] = useState(searchParams.get("token"));

    const [showRefreshTokenForm, setShowRefreshTokenForm] = useState<boolean>(false);

    if (success) {
        return <Navigate to="/customer" replace />
    }

    return (
        <>
            <div className="grid">
                <div className="row">
                    <div className="col grow-3 ignore">
                        <h2>Verifiera din kundprofil</h2>

                        <VerifyForm defaultToken={token} onVerified={() => {
                            setSuccess(true);
                        }} onError={() => {
                            setShowRefreshTokenForm(true);
                        }} />

                        {showRefreshTokenForm && <RequestTokenForm />}
                    </div>
                </div>
            </div>
        </>
    );
}
