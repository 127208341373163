import { useRef, useState } from "react";
import { renderForm } from "@agoransson/formhelper";
import { UpdateCustomerAction, UpdateCustomerRef } from "../../actions/UpdateCustomerAction";
import { useTranslation } from "react-i18next";
import { Customer } from "../../gql/graphql";
import { Button } from "../Button";

type Props = {
    customer: Customer
}

export default function UpdateCustomerForm({ customer }: Props) {

    const { t } = useTranslation("user");

    const updateCustomerAction = useRef<UpdateCustomerRef>(null);

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState(customer?.title || "");
    const [firstName, setFirstName] = useState(customer?.firstName || "");
    const [lastName, setLastName] = useState(customer?.lastName || "");
    const [phoneNumber, setPhoneNumber] = useState(customer?.phoneNumber || "");

    const [error] = useState();

    const handleTitle = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(evt.currentTarget.value);
    };

    const handleFirstName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(evt.currentTarget.value);
    };

    const handleLastName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(evt.currentTarget.value);
    };

    const handlePhoneNumber = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(evt.currentTarget.value);
    };

    const customerForm = [
        [
            {type: 'text', name: 'firstName', placeholder: t("firstname"), value: firstName, onChange: handleFirstName, disabled: loading},
            {type: 'text', name: 'lastName', placeholder: t("lastname"), value: lastName, onChange: handleLastName, disabled: loading},
        ],
        [
            {type: 'text', name: 'title', placeholder: t("title"), value: title, onChange: handleTitle, disabled: loading},
            {type: 'text', name: 'phoneNumber', placeholder: t("phone"), value: phoneNumber, onChange: handlePhoneNumber, disabled: loading},
        ]
    ];

    const updateHandler = () => {
        setLoading(true);
        
        const data = {
            title, firstName, lastName, phoneNumber
        };

        const updateData = {...data, ...{id: customer.id}};
        updateCustomerAction.current?.update(updateData).then(() => {
            setLoading(false);
        });
    };

    return (
        <>
            <UpdateCustomerAction ref={updateCustomerAction} />

            <form>
                <fieldset className="fieldset">
                    <legend>{t("title_user")}</legend> 
                    {renderForm(customerForm)}
                    {error && <p className="formError">{error}</p>}
                    <Button
                        fullWidth={true}
                        loading={loading}
                        onClick={updateHandler}>{loading ? t("saving_user") : t("save_user")}</Button>
                </fieldset>
            </form>
        </>
    );
}
