import classes from "./PageWrapper.module.scss";

type Props = {
    children: JSX.Element[] | JSX.Element,
};

const page = (children: JSX.Element[] | JSX.Element) => (
    <div className={classes.wrapper}>
        {children}
    </div>
);

export default function PageWrapper(props: Props) {
    const { children } = props;

    // if (isArray(children)) {
    //     return (
    //         <>
    //             <Banner />
    //             {page(children[1])}
    //         </>
    //     );
    // }

    return (
        <>
            {page(children)}
        </>        
    );
}
