import { useLazyQuery } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { SearchQuery } from "./SearchQuery";
import { TypeQuery } from "./TypeQuery";
import { SearchResult } from "../../gql/graphql";

export interface SearchRef {
    get: (slug: string, groupByProduct: boolean) => void,
    readyMade: () => void,
    madeToOrder: () => void,
    resetStore: () => void
}

const MADE_TO_ORDER_ID = 40;
const READY_MADE_ID = 41;

function SearchAction (
    props: {onSearch: (search: Array<SearchResult>) => void},
    ref: Ref<SearchRef>
) {
    const { onSearch } = props;
    
    const [query, { client }] = useLazyQuery<{search: { items: Array<SearchResult>}}, {input: { collectionSlug: string, groupByProduct: boolean }, slug: string}>(SearchQuery, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            onSearch(data.search.items)
        }
    });

    const [typeQuery] = useLazyQuery<{search: { items: Array<SearchResult>}}, {input: { groupByProduct?: boolean, facetValueFilters: Array<{or: number[];}>, inStock?: boolean }}>(TypeQuery, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            onSearch(data.search.items);
        }
    });

    const get = (slug: string, groupByProduct: boolean): void => {
        query({
            variables: {
                input: {
                    collectionSlug: slug, groupByProduct
                },
                slug
            },
        });
    }


    const readyMade = (): void => {
        typeQuery({
            variables: {
                input: {
                    facetValueFilters: [
                        { or: [READY_MADE_ID] }
                    ],
                    groupByProduct: true,
                    inStock: true
                }
            }
        });
    }

    const madeToOrder = (): void => {
        typeQuery({
            variables: {
                input: {
                    facetValueFilters: [
                        { or: [MADE_TO_ORDER_ID] }
                    ],
                    groupByProduct: true
                }
            }
        });
    }

    const resetStore = () => {
        if (!client) {
            return;
        }

        client.resetStore();
    }

    useImperativeHandle(ref, () => ({ get, readyMade, madeToOrder, resetStore }));

    return <></>;
}

export default React.forwardRef(SearchAction);
