import { gql } from "@apollo/client";

export const AddItemToOrderQuery = gql`
mutation AddItemToOrder($productVariantId: ID!, $quantity: Int!) {
    addItemToOrder(productVariantId: $productVariantId, quantity: $quantity) {
        ... on Order {
            __typename
            id
            code
        }
        ... on OrderModificationError {
            __typename
            message
        }
        ... on OrderLimitError {
            __typename
            message
            maxItems
        }
        ... on NegativeQuantityError {
            __typename
            message
        }
        ... on InsufficientStockError {
            __typename
            message
            quantityAvailable
            order {
                id
            }
        }
    }
}
`;
