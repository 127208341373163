import { gql } from "@apollo/client";

export const RegisterCustomerAccountQuery = gql`
mutation RegisterCustomerAccount($input: RegisterCustomerInput!) {
    registerCustomerAccount(input: $input) {
        ... on Success {
            __typename
            success
        }
        ... on MissingPasswordError {
            __typename
            message
        }
        ... on NativeAuthStrategyError {
            __typename
            message
        }
        ... on PasswordValidationError {
            __typename
            message
        }
    }
}
`;
