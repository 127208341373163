import { useLazyQuery, useMutation } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { AddPaymentToOrderQuery, EligiblePaymentMethodsQuery } from ".";
import { AddPaymentToOrderResult, PaymentMethodQuote } from "../../gql/graphql";

type PaymentMethodVars = {
    method: string;
    metadata: Object;
}

type Props = {
    onPaymentMethods?: (result: Array<PaymentMethodQuote>) => void;
}

export interface PaymentMethodRef {
    get: () => Promise<Array<PaymentMethodQuote>>;
    set: (method: string, metadata: Object) => Promise<AddPaymentToOrderResult>;
    resetStore: () => void;
}

function PaymentMethodAction (
    { onPaymentMethods }: Props,
    ref: Ref<PaymentMethodRef>
) {
    const [setMethod, { client }] = useMutation<{addPaymentToOrder: AddPaymentToOrderResult}, { input: PaymentMethodVars }>(AddPaymentToOrderQuery);

    const [getMethods] = useLazyQuery<{eligiblePaymentMethods: Array<PaymentMethodQuote>}>(EligiblePaymentMethodsQuery, {
        onCompleted: ({ eligiblePaymentMethods }) => {
            onPaymentMethods?.(eligiblePaymentMethods);
        }
    });

    const get = (): Promise<Array<PaymentMethodQuote>> => (
        new Promise<Array<PaymentMethodQuote>>((resolve, reject) => {
            getMethods({
                onCompleted: (result) => {
                    resolve(result.eligiblePaymentMethods);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    );

    const set = (method: string, metadata: Object): Promise<AddPaymentToOrderResult> => (
        new Promise<AddPaymentToOrderResult>((resolve, reject) => {
            setMethod({
                variables: {
                    input: {
                        method,
                        metadata
                    }
                },
                onCompleted: (result) => {
                    resolve(result.addPaymentToOrder);
                },
                onError: (error) => {
                    reject(error);
                }
            })
        })
    );

    const resetStore = () => {
        client.resetStore();
    }

    useImperativeHandle(ref, () => ({ get, set, resetStore }));

    return <></>;
}

export default React.forwardRef(PaymentMethodAction);
