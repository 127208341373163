import { useEffect, useRef, useState } from "react";
import { GalleryAction, GalleryRef } from "../../actions/GalleryAction"
import PhotoAlbum, { Photo } from "react-photo-album";
import { useTranslation } from "react-i18next";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export default function GalleryPage() {

    const { t } = useTranslation("gallery");

    const galleryAction = useRef<GalleryRef>(null);

    const [loaded, setLoaded] = useState(false);
    const [photos, setPhotos] = useState<Array<Photo>>([]);
    const [index, setIndex] = useState(-1);

    useEffect(() => {
        if (!loaded) {
            galleryAction.current?.get();
            setLoaded(true);
        }
    }, [loaded]);
    
    const breakpoints = [1680, 1440, 1080];

    return (
        <>
            <GalleryAction ref={galleryAction} onCompleted={(gallery) => {
                const photos: Array<Photo> = gallery.map((asset) => ({
                    src: `${asset.source}`,
                    width: asset.width,
                    height: asset.height,
                    srcSet: breakpoints.map((breakpoint) => {
                        const height = Math.round((asset.height / asset.width) * breakpoint);
                        return {
                            src: `${asset.source}?w=${breakpoint}&h=${height}&mode=crop`,
                            width: breakpoint,
                            height
                        };
                    })
                }));

                setPhotos(photos);
            }} />
            <div className="row">
                <div className="column">
                    <h1>{t("title")}</h1>

                    <PhotoAlbum
                        layout="masonry"
                        columns={(containerWidth) => {
                            if (containerWidth < 400) return 2;
                            if (containerWidth < 800) return 3;
                            return 4;
                        }}
                        photos={photos}
                        onClick={({ index }) => setIndex(index)}
                        />

                    <Lightbox
                        slides={photos}
                        open={index >= 0}
                        index={index}
                        zoom={{
                            scrollToZoom: true
                        }}
                        close={() => setIndex(-1)}
                        // enable optional lightbox plugins
                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                    />
                </div>
            </div>
        </>
    );
}
