import { gql } from "@apollo/client";
import { AssetFragment, ProductFragment, VariantsFragment, OptionGroupsFragment } from "./ProductFragment";

export const ProductsQuery = gql`
    ${ProductFragment}
    ${AssetFragment}
    ${VariantsFragment}
    ${OptionGroupsFragment}
    query Products($options: ProductListOptions) {
        products(options: $options) {
            items {
                languageCode
                ...ProductFragment
                ...AssetFragment
                ...VariantsFragment
                ...OptionGroupsFragment
            }
            totalItems
        }
    }
`;
