import { useRef, useState } from "react";
import { AccordionButtons, AccordionItem, AccordionItemInterface } from "../../Accordion";
import PaymentMethodSelector from "./PaymentMethodSelector";
import { useTranslation } from "react-i18next";
import { AccordionButtonInterface } from "../../Accordion/AccordionButtons";
import { AccordionItemRef } from "../../Accordion/AccordionItem";

export default function PaymentsRow({
    onPrevious, onNext,
    ...rest
}: AccordionButtonInterface & AccordionItemInterface) {

    const { t } = useTranslation("checkout");

    const accordionItem = useRef<AccordionItemRef>(null);

    const [ready, setReady] = useState(false);

    return (
        <AccordionItem ref={accordionItem} title={t("select_payment_method")} {...rest}>
            <PaymentMethodSelector isOpen={accordionItem?.current?.isOpen()} onReady={(ready) => {
                setReady(ready);
            }} />

            <AccordionButtons {...{onPrevious}} isReady={ready} />
        </AccordionItem>
    );
}
