import { PaymentMethodQuote } from "../../../gql/graphql";
import classes from "./PaymentMethodSelector.module.scss";

type Props = {
    paymentMethod: PaymentMethodQuote;
    selected: boolean;
    onClick: () => void;
}

export default function SelectorItem({ paymentMethod, selected, onClick }: Props) {

    let containerClasses = [classes.itemContainer];

    if (selected) {
        containerClasses = [classes.selected, ...containerClasses];
    }
    
    return (
        <div className={containerClasses.join(" ")} onClick={() => {
            onClick();
        }}>
            <p className={classes.title}>{paymentMethod.name}</p>

            <span className={classes.body} dangerouslySetInnerHTML={{__html: paymentMethod.description}} />
            <span className={classes.body} dangerouslySetInnerHTML={{__html: paymentMethod.eligibilityMessage ? paymentMethod.eligibilityMessage : ""}} />
        </div>
    );
}
